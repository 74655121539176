import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Skull } from 'lucide-react';
import { useSettings } from '@/hooks/use-settings';
import { cn } from '@/lib/utils';

interface DegenModeToggleProps {
  compact?: boolean;
  onToggle?: () => void;
}

export function DegenModeToggle({ compact = false, onToggle }: DegenModeToggleProps) {
  const { degenMode, toggleDegenMode } = useSettings();

  const handleToggle = () => {
    toggleDegenMode();
    onToggle?.();
  };

  return (
    <div className="flex items-center gap-2">
      <Switch
        id="degen-mode"
        checked={degenMode}
        onCheckedChange={handleToggle}
      />
      <Label 
        htmlFor="degen-mode" 
        className={cn(
          "flex cursor-pointer items-center gap-2",
          compact && "md:hidden"
        )}
      >
        <Skull className="h-4 w-4" />
        {!compact && <span>Degen Mode</span>}
      </Label>
    </div>
  );
}