import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { useFeedStore } from '@/lib/store';
import { useToast } from '@/hooks/use-toast';
import { useArticleAnalysis } from '@/hooks/use-article-analysis';
import { cn } from '@/lib/utils';
import { ArticleCardContent } from './article/ArticleCardContent';

interface ArticleCardProps {
  article: any;
  isHidden?: boolean;
  onHide: () => void;
}

export function ArticleCard({ article, isHidden = false, onHide }: ArticleCardProps) {
  const { toggleFavorite, favorites } = useFeedStore();
  const [imageError, setImageError] = useState(false);
  const [votes, setVotes] = useState({ up: 0, down: 0 });
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null);
  const { analyze, analysis, loading } = useArticleAnalysis();
  const { toast } = useToast();

  const isFavorited = favorites.includes(article.url || article.link);
  const hasValidImage = article.image && !article.image.includes('undefined') && !imageError;
  const canAnalyze = !article.link.includes('bloomberg.com') && !article.link.includes('wsj.com');

  const handleCardClick = (e: React.MouseEvent) => {
    if (!(e.target as HTMLElement).closest('button, input, a, [role="button"]')) {
      window.open(article.link, '_blank');
    }
  };

  const handleVote = (type: 'up' | 'down', e: React.MouseEvent) => {
    e.stopPropagation();
    if (userVote === type) {
      setVotes(prev => ({
        ...prev,
        [type]: prev[type] - 1
      }));
      setUserVote(null);
    } else {
      setVotes(prev => ({
        ...prev,
        [type]: prev[type] + 1,
        ...(userVote && { [userVote]: prev[userVote] - 1 })
      }));
      setUserVote(type);
    }
  };

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.share({
        title: article.title,
        url: article.link
      });
    } catch {
      navigator.clipboard.writeText(article.link);
      toast({
        title: 'Link copied',
        description: 'Article link copied to clipboard'
      });
    }
  };

  const handleAnalyze = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!canAnalyze) return;
    analyze(article.link);
  };

  const handleFavorite = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleFavorite(article.url || article.link);
  };

  const handleHide = (e: React.MouseEvent) => {
    e.stopPropagation();
    onHide();
  };

  return (
    <Card 
      className={cn(
        "group relative h-full overflow-hidden bg-card/50 rounded-[18px] transition-all duration-300 hover:bg-card/80 hover:shadow-[0_0_30px_rgba(255,170,0,0.2)] cursor-pointer"
      )}
      onClick={handleCardClick}
    >
      <ArticleCardContent
        article={article}
        isHidden={isHidden}
        isFavorited={isFavorited}
        votes={votes}
        userVote={userVote}
        hasValidImage={hasValidImage}
        imageError={imageError}
        onVote={handleVote}
        onShare={handleShare}
        onFavorite={handleFavorite}
        onHide={handleHide}
        onAnalyze={handleAnalyze}
        canAnalyze={canAnalyze}
        analysis={analysis}
        loading={loading}
      />
    </Card>
  );
}