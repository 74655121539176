import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TokenState {
  gems: number;
  updateGems: (amount: number) => void;
}

export const useTokenStore = create<TokenState>()(
  persist(
    (set) => ({
      gems: 1250,
      updateGems: (amount) => set((state) => ({
        gems: state.gems + amount
      })),
    }),
    {
      name: 'token-storage',
      version: 1,
    }
  )
);