import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Brain, CheckCircle2, AlertCircle, Loader2 } from 'lucide-react';
import { SuggestedTicker } from './SuggestedTicker';

interface ArticleAnalysisModalProps {
  open: boolean;
  onClose: () => void;
  analysis: {
    story_title: string;
    key_points: string[];
    tickers: string[];
  } | null;
  loading?: boolean;
  error?: string | null;
}

export function ArticleAnalysisModal({ 
  open, 
  onClose, 
  analysis,
  loading,
  error 
}: ArticleAnalysisModalProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Brain className="h-5 w-5 text-primary" />
            Article Analysis
          </DialogTitle>
        </DialogHeader>

        <ScrollArea className="max-h-[60vh] pr-4">
          {loading ? (
            <div className="flex h-40 items-center justify-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
            </div>
          ) : error ? (
            <div className="flex h-40 items-center justify-center text-center">
              <div className="space-y-2">
                <AlertCircle className="mx-auto h-8 w-8 text-destructive" />
                <p className="text-sm text-muted-foreground">{error}</p>
              </div>
            </div>
          ) : analysis ? (
            <div className="space-y-6">
              <div>
                <h3 className="mb-2 font-medium text-primary">Story Title</h3>
                <p className="text-sm text-muted-foreground">{analysis.story_title}</p>
              </div>

              <div>
                <h3 className="mb-2 font-medium text-primary">Key Points</h3>
                <ul className="space-y-2">
                  {analysis.key_points.map((point, index) => (
                    <li key={index} className="flex items-start gap-2 text-sm text-muted-foreground">
                      <CheckCircle2 className="mt-1 h-4 w-4 flex-shrink-0 text-green-500" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {analysis.tickers.length > 0 && (
                <div>
                  <h3 className="mb-2 font-medium text-primary">Suggested Tickers</h3>
                  <div className="flex flex-wrap gap-2">
                    {analysis.tickers.map((ticker) => (
                      <SuggestedTicker key={ticker} ticker={ticker} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}