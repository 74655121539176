import { useEffect } from 'react';
import { BookOpen } from 'lucide-react';
import { useBlogStore } from '@/lib/store/blog-store';
import { BlogCard } from '@/components/blog/BlogCard';

export default function Blog() {
  const { posts, loadPosts } = useBlogStore();

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <BookOpen className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Blog</h1>
          <p className="text-muted-foreground">Latest insights and analysis</p>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {posts.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
}