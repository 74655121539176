import { cn } from '@/lib/utils';
import { Header } from './sidebar/Header';
import Navigation from './sidebar/Navigation';
import { UserProfile } from './sidebar/UserProfile';

interface SidebarProps {
  onClose?: () => void;
}

export default function Sidebar({ onClose }: SidebarProps) {
  return (
    <div className={cn("flex h-full flex-col bg-background border-r border-border")}>
      <Header onClose={onClose} />
      <div className="flex-1 overflow-y-auto">
        <Navigation onClose={onClose} />
      </div>
      <UserProfile onClose={onClose} />
      
      {/* Gradient circle */}
      <div className="absolute bottom-24 left-8 h-32 w-32 rounded-full bg-gradient-to-r from-[#FFAA00]/20 to-[#CA5003]/20 blur-2xl pointer-events-none" />
    </div>
  );
}