import { useState, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Search, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useFeedStore } from '@/lib/store';
import { format } from 'date-fns';

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

export function SearchModal({ open, onClose }: SearchModalProps) {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { feeds } = useFeedStore();

  const searchResults = useMemo(() => {
    if (!query) return [];
    
    const searchQuery = query.toLowerCase();
    const results = [];

    // Search articles first
    const allArticles = Object.values(feeds).flat();
    const uniqueArticles = new Map();
    
    allArticles.forEach(article => {
      const url = article.link || article.url;
      if (!uniqueArticles.has(url) && 
          (article.title?.toLowerCase().includes(searchQuery) ||
           article.description?.toLowerCase().includes(searchQuery))) {
        uniqueArticles.set(url, {
          type: 'article',
          title: article.title,
          subtitle: article.description?.replace(/<[^>]*>/g, '').slice(0, 100) + '...',
          link: url,
          image: article.image,
          date: article.pubDate || article.date_published,
          source: new URL(url).hostname.replace('www.', '')
        });
      }
    });

    results.push(...Array.from(uniqueArticles.values()).slice(0, 5));

    return results;
  }, [query, feeds]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="sr-only">Search</DialogTitle>
        </DialogHeader>
        
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder="Search articles..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="pl-9"
            autoFocus
          />
        </div>

        {searchResults.length > 0 && (
          <div className="mt-4 space-y-4">
            {searchResults.map((result, index) => (
              <a
                key={result.link + index}
                href={result.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-start gap-3 rounded-lg p-2 text-left hover:bg-muted"
              >
                {result.image && (
                  <div className="relative h-16 w-24 flex-shrink-0 overflow-hidden rounded-md">
                    <img
                      src={result.image}
                      alt=""
                      className="h-full w-full object-cover"
                      onError={(e) => {
                        const target = e.target as HTMLElement;
                        target.style.display = 'none';
                      }}
                    />
                  </div>
                )}
                <div className="flex-1 overflow-hidden">
                  <div className="flex items-center gap-2">
                    <span className="flex-1 font-medium line-clamp-1">{result.title}</span>
                    <ExternalLink className="h-3 w-3 flex-shrink-0 text-muted-foreground" />
                  </div>
                  {result.subtitle && (
                    <div className="mt-1 text-sm text-muted-foreground line-clamp-2">
                      {result.subtitle}
                    </div>
                  )}
                  <div className="mt-1 flex items-center gap-2 text-xs text-muted-foreground">
                    <span>{result.source}</span>
                    {result.date && (
                      <>
                        <span>•</span>
                        <span>{format(new Date(result.date), 'MMM d, yyyy')}</span>
                      </>
                    )}
                  </div>
                </div>
              </a>
            ))}
          </div>
        )}

        {query && searchResults.length === 0 && (
          <div className="mt-4 text-center text-sm text-muted-foreground">
            No results found for "{query}"
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}