import { ExternalLink, Eye, EyeOff, Star, Share, ThumbsUp, ThumbsDown, Brain } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ArticleAge } from '../ArticleAge';
import { format } from 'date-fns';
import { ArticleInteractions } from '../ArticleInteractions';

interface ArticleCardContentProps {
  article: any;
  isHidden: boolean;
  isFavorited: boolean;
  votes: { up: number; down: number };
  userVote: 'up' | 'down' | null;
  hasValidImage: boolean;
  imageError: boolean;
  onVote: (type: 'up' | 'down', e: React.MouseEvent) => void;
  onShare: (e: React.MouseEvent) => void;
  onFavorite: (e: React.MouseEvent) => void;
  onHide: (e: React.MouseEvent) => void;
  onAnalyze: (e: React.MouseEvent) => void;
  canAnalyze?: boolean;
  analysis: { story_title: string; key_points: string[]; tickers: string[] } | null;
  loading: boolean;
}

export function ArticleCardContent({
  article,
  isHidden,
  isFavorited,
  votes,
  userVote,
  hasValidImage,
  imageError,
  onVote,
  onShare,
  onFavorite,
  onHide,
  onAnalyze,
  canAnalyze = true,
  analysis,
  loading
}: ArticleCardContentProps) {
  const pubDate = new Date(article.pubDate || article.date_published || 0);
  const hostname = new URL(article.link).hostname.replace('www.', '');

  return (
    <div className="flex h-full flex-col">
      {hasValidImage && !imageError && (
        <div className="aspect-video w-full overflow-hidden">
          <img
            src={article.image}
            alt={article.title}
            className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.style.display = 'none';
            }}
          />
        </div>
      )}

      <div className="flex flex-1 flex-col p-6">
        <div className="mb-2">
          <ArticleAge date={article.pubDate} className="inline-block" />
        </div>

        <div className="group/title inline-flex items-center gap-2">
          <h3 className="line-clamp-2 text-lg font-semibold text-white group-hover/title:text-[#FFAA00]">
            {article.title}
          </h3>
          <ExternalLink className="h-4 w-4 opacity-0 transition-opacity group-hover/title:opacity-100" />
        </div>

        <div className="relative mt-2 flex-grow">
          <p className="line-clamp-3 text-sm text-gray-400">
            {article.description?.replace(/<[^>]*>/g, '')}
          </p>
          <div className="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-black/40 to-transparent" />
        </div>

        {analysis && (
          <div className="mt-4 space-y-4 border-t border-white/10 pt-4">
            <div className="space-y-2">
              <h4 className="font-medium text-primary">Key Points:</h4>
              <ul className="space-y-1 text-sm text-gray-400">
                {analysis.key_points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>

            {analysis.tickers?.length > 0 && (
              <div className="space-y-2">
                <h4 className="font-medium text-primary">Mentioned Tokens:</h4>
                <div className="flex flex-wrap gap-2">
                  {analysis.tickers.map((ticker) => (
                    <span
                      key={ticker}
                      className="rounded-full bg-primary/10 px-3 py-1 text-xs font-medium text-primary"
                    >
                      {ticker.replace('$', '')}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="mt-4 flex items-center gap-2 text-xs text-gray-400">
          <span>{hostname}</span>
          <span className="text-gray-600">•</span>
          <span>{format(pubDate, 'MMM d, yyyy h:mm a')}</span>
        </div>

        <div className="mt-4 space-y-4 border-t border-white/10 pt-4">
          <div className="flex items-center justify-between gap-1">
            <div className="flex items-center gap-1">
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => onVote('up', e)}
                className={cn(
                  "hover:text-[#FFAA00]",
                  userVote === 'up' && "text-[#FFAA00]"
                )}
              >
                <ThumbsUp className="mr-1 h-4 w-4" />
                <span>{votes.up}</span>
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => onVote('down', e)}
                className={cn(
                  "hover:text-[#CA5003]",
                  userVote === 'down' && "text-[#CA5003]"
                )}
              >
                <ThumbsDown className="mr-1 h-4 w-4" />
                <span>{votes.down}</span>
              </Button>
            </div>

            <div className="flex items-center gap-1">
              <Button
                variant="ghost"
                size="sm"
                onClick={onShare}
                className="hover:text-[#FFAA00]"
              >
                <Share className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={onAnalyze}
                disabled={!canAnalyze || loading}
                className={cn(
                  "gap-2 px-3",
                  canAnalyze && "border-[#FFAA00] bg-[#FFAA00]/10 text-[#FFAA00] hover:bg-[#FFAA00]/20"
                )}
              >
                <Brain className="h-4 w-4" />
                {loading ? 'Analyzing...' : 'Analyze'}
              </Button>
            </div>
          </div>

          <ArticleInteractions articleId={article.link || article.url} />
        </div>
      </div>

      <div className="absolute right-2 top-2 z-10 flex gap-2">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 rounded-full bg-black/20 backdrop-blur-sm hover:bg-black/40"
          onClick={onFavorite}
        >
          <Star 
            className={cn("h-4 w-4", isFavorited ? "text-[#FFAA00]" : "text-white")} 
            fill={isFavorited ? "currentColor" : "none"} 
          />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 rounded-full bg-black/20 backdrop-blur-sm hover:bg-black/40"
          onClick={onHide}
        >
          {isHidden ? (
            <EyeOff className="h-4 w-4 text-white" />
          ) : (
            <Eye className="h-4 w-4 text-white" />
          )}
        </Button>
      </div>
    </div>
  );
}