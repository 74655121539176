import { useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { EventForm } from './EventForm';
import { EventsTable } from './EventsTable';
import { BlogForm } from './BlogForm';
import { BlogsTable } from './BlogsTable';
import { useEventsStore } from '@/lib/store/events-store';
import { useBlogStore } from '@/lib/store/blog-store';
import { useToast } from '@/hooks/use-toast';

interface AdminContentProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  editId?: string | null;
}

export function AdminContent({ activeTab, onTabChange, editId }: AdminContentProps) {
  const navigate = useNavigate();
  const { events, addEvent, updateEvent } = useEventsStore();
  const { addPost } = useBlogStore();
  const { toast } = useToast();

  const eventToEdit = editId ? events.find(e => e.id === editId) : undefined;

  const handleEventSubmit = (data: any) => {
    if (editId) {
      updateEvent(editId, data);
      toast({
        title: 'Event updated',
        description: 'The event has been successfully updated.'
      });
    } else {
      addEvent(data);
      toast({
        title: 'Event created',
        description: 'The event has been successfully created.'
      });
    }
    onTabChange('list');
  };

  const handleBlogSubmit = (data: any) => {
    addPost(data);
    toast({
      title: 'Success',
      description: 'Blog post created successfully'
    });
    navigate('/'); // Redirect to dashboard
  };

  return (
    <Tabs value={activeTab} onValueChange={onTabChange}>
      <TabsList>
        <TabsTrigger value="list">Events List</TabsTrigger>
        <TabsTrigger value="add">
          {editId ? 'Edit Event' : 'Add Event'}
        </TabsTrigger>
        <TabsTrigger value="blogs">Blogs List</TabsTrigger>
        <TabsTrigger value="add-blog">
          {editId ? 'Edit Blog' : 'Add Blog'}
        </TabsTrigger>
      </TabsList>
      
      <TabsContent value="list" className="mt-6">
        <EventsTable />
      </TabsContent>
      
      <TabsContent value="add" className="mt-6">
        <EventForm 
          initialData={eventToEdit}
          onSubmit={handleEventSubmit}
        />
      </TabsContent>

      <TabsContent value="blogs" className="mt-6">
        <BlogsTable />
      </TabsContent>

      <TabsContent value="add-blog" className="mt-6">
        <BlogForm onSubmit={handleBlogSubmit} />
      </TabsContent>
    </Tabs>
  );
}