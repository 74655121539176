import { useEffect, useMemo, useState } from 'react';
import { RefreshCw, ArrowUpDown, Newspaper } from 'lucide-react';
import { useFeedStore } from '@/lib/store';
import { Button } from '@/components/ui/button';
import { groupSimilarArticles } from '@/lib/utils/groupArticles';
import { sections } from '@/lib/constants/feeds';
import { StoryCard } from '@/components/StoryCard';
import { generateUniqueId } from '@/lib/utils/generateId';

export default function Stories() {
  const { feeds, fetchFeeds } = useFeedStore();
  const [sortByCount, setSortByCount] = useState(false);

  useEffect(() => {
    fetchFeeds();
  }, [fetchFeeds]);

  const storiesBySection = useMemo(() => {
    const result: Record<string, ReturnType<typeof groupSimilarArticles>> = {};
    
    sections.forEach(({ key }) => {
      const sectionArticles = feeds[key] || [];
      const stories = groupSimilarArticles(sectionArticles, sortByCount)
        .map(story => ({
          ...story,
          id: generateUniqueId(`${key}-`)
        }));
      
      if (stories.some(story => story.articles.length > 1)) {
        result[key] = stories;
      }
    });

    return result;
  }, [feeds, sortByCount]);

  const sectionsWithStories = Object.entries(storiesBySection);

  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <Newspaper className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Stories</h1>
          <p className="text-muted-foreground">Latest news and updates</p>
        </div>
        <div className="ml-auto flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setSortByCount(!sortByCount)}
            className="flex items-center gap-2"
          >
            <ArrowUpDown className="h-4 w-4" />
            Sort by {sortByCount ? 'Date' : 'Coverage'}
          </Button>
          <Button
            onClick={() => fetchFeeds()}
            className="bg-gradient-to-r from-[#FFAA00] to-[#CA5003] text-white hover:from-[#FFAA00]/90 hover:to-[#CA5003]/90"
          >
            <RefreshCw className="mr-2 h-4 w-4" />
            Refresh Stories
          </Button>
        </div>
      </div>

      <div className="space-y-8">
        {sectionsWithStories.map(([key, stories]) => (
          <section key={key} className="space-y-4">
            <h2 className="text-xl font-semibold">{sections.find(s => s.key === key)?.title}</h2>
            <div className="relative w-full overflow-hidden">
              <div 
                className="no-scrollbar flex gap-6 overflow-x-auto pb-6 snap-x"
                style={{
                  WebkitOverflowScrolling: 'touch',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none'
                }}
              >
                {stories
                  .filter(story => story.articles.length > 1)
                  .map((story) => (
                    <StoryCard key={story.id} story={story} />
                  ))}
              </div>
            </div>
          </section>
        ))}

        {sectionsWithStories.length === 0 && (
          <div className="flex h-[400px] items-center justify-center text-muted-foreground">
            No stories found. Try refreshing the feed.
          </div>
        )}
      </div>
    </div>
  );
}