import { useNavigate } from 'react-router-dom';
import { Search, Star, MessageSquare, User, Shield } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAuth } from '@/lib/auth';

export function ProfileMenu() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const menuItems = [
    { icon: Search, label: 'Search', action: () => navigate('/search') },
    { icon: Star, label: 'Favorites', action: () => navigate('/favorites') },
    { icon: MessageSquare, label: 'Blurts', action: () => navigate('/blurts') },
    { icon: User, label: 'Settings', action: () => navigate('/settings') },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8">
          <Avatar className="h-8 w-8">
            <AvatarImage src={user?.avatar} />
            <AvatarFallback>
              {user?.username?.slice(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-48">
        {menuItems.map((item) => (
          <DropdownMenuItem
            key={item.label}
            onClick={item.action}
            className="flex items-center gap-2 p-2"
          >
            <item.icon className="h-4 w-4" />
            <span>{item.label}</span>
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => navigate('/admin')}
          className="flex items-center gap-2 p-2"
        >
          <Shield className="h-4 w-4" />
          <span>Admin</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}