import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import { Loader2, Upload, Link as LinkIcon, X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface BlogFormProps {
  initialData?: any;
  onSubmit: (data: any) => void;
}

export function BlogForm({ initialData, onSubmit }: BlogFormProps) {
  const { toast } = useToast();
  const [isExtracting, setIsExtracting] = useState(false);
  const [formData, setFormData] = useState({
    url: initialData?.url || '',
    title: initialData?.title || '',
    content: initialData?.content || '',
    excerpt: initialData?.excerpt || '',
    author: initialData?.author || '',
    date: initialData?.date || new Date().toISOString().split('T')[0],
    tags: initialData?.tags || '',
    image: initialData?.image || '',
    imageFile: null as File | null
  });
  const [imagePreview, setImagePreview] = useState(initialData?.image || '');

  const handleImageUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setFormData(prev => ({ ...prev, image: url, imageFile: null }));
    setImagePreview(url);
  };

  const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData(prev => ({ ...prev, imageFile: file, image: '' }));
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    }
  };

  const clearImage = () => {
    setFormData(prev => ({ ...prev, image: '', imageFile: null }));
    setImagePreview('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let imageUrl = formData.image;

    if (formData.imageFile) {
      // Convert file to base64
      const reader = new FileReader();
      imageUrl = await new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(formData.imageFile);
      });
    }

    const submitData = {
      ...formData,
      image: imageUrl,
      tags: formData.tags.split(',').map(t => t.trim()).filter(Boolean)
    };
    delete submitData.imageFile;
    onSubmit(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* URL field */}
      <div className="flex gap-4">
        <div className="flex-1 space-y-2">
          <Label htmlFor="url">URL</Label>
          <Input
            id="url"
            value={formData.url}
            onChange={(e) => setFormData(prev => ({ ...prev, url: e.target.value }))}
            placeholder="https://example.com/article"
          />
        </div>
        <div className="flex items-end">
          <Button 
            type="button" 
            variant="secondary"
            onClick={() => {/* Extract content */}}
            disabled={isExtracting}
          >
            {isExtracting ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            Extract
          </Button>
        </div>
      </div>

      {/* Image Upload Section */}
      <div className="space-y-2">
        <Label>Blog Image</Label>
        <div className="space-y-4">
          {/* Image URL input */}
          <div className="flex items-center gap-2">
            <Input
              type="url"
              value={formData.image}
              onChange={handleImageUrlChange}
              placeholder="Image URL (e.g., https://example.com/image.jpg)"
              className="flex-1"
            />
            <span className="text-sm text-muted-foreground">OR</span>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageFileChange}
              className="w-[200px]"
            />
          </div>

          {/* Image Preview */}
          {imagePreview && (
            <div className="relative inline-block">
              <img
                src={imagePreview}
                alt="Preview"
                className="max-h-[200px] rounded-lg object-cover"
              />
              <Button
                type="button"
                variant="destructive"
                size="icon"
                className="absolute right-2 top-2"
                onClick={clearImage}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Title field */}
      <div className="space-y-2">
        <Label htmlFor="title">Title</Label>
        <Input
          id="title"
          value={formData.title}
          onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
          placeholder="Article Title"
        />
      </div>

      {/* Content field */}
      <div className="space-y-2">
        <Label htmlFor="content">Content</Label>
        <Textarea
          id="content"
          value={formData.content}
          onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
          placeholder="Article content..."
          className="min-h-[200px]"
        />
      </div>

      {/* Excerpt field */}
      <div className="space-y-2">
        <Label htmlFor="excerpt">Excerpt</Label>
        <Textarea
          id="excerpt"
          value={formData.excerpt}
          onChange={(e) => setFormData(prev => ({ ...prev, excerpt: e.target.value }))}
          placeholder="Brief excerpt..."
          className="min-h-[100px]"
        />
      </div>

      {/* Author and Date fields */}
      <div className="grid gap-4 sm:grid-cols-2">
        <div className="space-y-2">
          <Label htmlFor="author">Author</Label>
          <Input
            id="author"
            value={formData.author}
            onChange={(e) => setFormData(prev => ({ ...prev, author: e.target.value }))}
            placeholder="Author name"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="date">Date</Label>
          <Input
            id="date"
            type="date"
            value={formData.date}
            onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
          />
        </div>
      </div>

      {/* Tags field */}
      <div className="space-y-2">
        <Label htmlFor="tags">Tags</Label>
        <Input
          id="tags"
          value={formData.tags}
          onChange={(e) => setFormData(prev => ({ ...prev, tags: e.target.value }))}
          placeholder="Comma-separated tags"
        />
      </div>

      <Button type="submit" className="w-full">
        {initialData ? 'Update Blog' : 'Create Blog'}
      </Button>
    </form>
  );
}