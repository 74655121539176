import { create } from 'zustand';
import { leaderboardData, type LeaderboardEntry } from '@/lib/data/leaderboard';

interface LeaderboardState {
  entries: LeaderboardEntry[];
  getUserRank: (userId: string) => number;
  getTopPerformers: (limit?: number) => LeaderboardEntry[];
  getWeeklyTopPerformers: (limit?: number) => LeaderboardEntry[];
}

export const useLeaderboardStore = create<LeaderboardState>(() => ({
  entries: leaderboardData,
  getUserRank: (userId) => {
    const entry = leaderboardData.find(e => e.id === userId);
    return entry?.rank || 0;
  },
  getTopPerformers: (limit = 10) => {
    return leaderboardData
      .sort((a, b) => b.totalPnl - a.totalPnl)
      .slice(0, limit);
  },
  getWeeklyTopPerformers: (limit = 10) => {
    return leaderboardData
      .sort((a, b) => b.weeklyPnl - a.weeklyPnl)
      .slice(0, limit);
  }
}));