import { create } from 'zustand';
import { supabase } from '@/lib/supabase/client';
import { FEED_PRESETS, FEED_ORDER } from '@/lib/constants/feeds';

interface SettingsState {
  degenMode: boolean;
  enabledFeeds: string[];
  feedOrder: string[];
  isLoading: boolean;
  error: string | null;
  loadSettings: () => Promise<void>;
  toggleDegenMode: () => Promise<void>;
  setEnabledFeeds: (feeds: string[]) => Promise<void>;
}

export const useSettings = create<SettingsState>((set, get) => ({
  degenMode: false,
  enabledFeeds: Object.entries(FEED_PRESETS.default)
    .filter(([_, enabled]) => enabled)
    .map(([key]) => key),
  feedOrder: FEED_ORDER.default,
  isLoading: false,
  error: null,

  loadSettings: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data: userSettings, error: settingsError } = await supabase
        .from('user_settings')
        .select('*')
        .single();

      const { data: feedSettings, error: feedError } = await supabase
        .from('user_feed_settings')
        .select('*')
        .single();

      if (settingsError || feedError) throw settingsError || feedError;

      if (userSettings) {
        set({ degenMode: userSettings.degen_mode });
      }

      if (feedSettings) {
        set({
          enabledFeeds: feedSettings.enabled_feeds,
          feedOrder: feedSettings.feed_order
        });
      }
    } catch (error) {
      set({ error: 'Failed to load settings' });
      console.error('Error loading settings:', error);
    } finally {
      set({ isLoading: false });
    }
  },

  toggleDegenMode: async () => {
    try {
      const newMode = !get().degenMode;
      const preset = newMode ? FEED_PRESETS.degen : FEED_PRESETS.default;
      const feeds = Object.entries(preset)
        .filter(([_, enabled]) => enabled)
        .map(([key]) => key);
      const feedOrder = newMode ? FEED_ORDER.degen : FEED_ORDER.default;

      const { error: settingsError } = await supabase
        .from('user_settings')
        .upsert({ degen_mode: newMode })
        .single();

      const { error: feedError } = await supabase
        .from('user_feed_settings')
        .upsert({
          enabled_feeds: feeds,
          feed_order: feedOrder
        })
        .single();

      if (settingsError || feedError) throw settingsError || feedError;

      set({
        degenMode: newMode,
        enabledFeeds: feeds,
        feedOrder
      });
    } catch (error) {
      set({ error: 'Failed to update settings' });
      console.error('Error updating settings:', error);
    }
  },

  setEnabledFeeds: async (feeds) => {
    try {
      // Always keep Bitcoin News enabled
      const updatedFeeds = Array.from(new Set(['bitcoin', ...feeds]));

      const { error } = await supabase
        .from('user_feed_settings')
        .upsert({ enabled_feeds: updatedFeeds })
        .single();

      if (error) throw error;

      set({ enabledFeeds: updatedFeeds });
    } catch (error) {
      set({ error: 'Failed to update feed settings' });
      console.error('Error updating feed settings:', error);
    }
  },
}));