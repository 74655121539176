import { Card } from '@/components/ui/card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { ExternalLink } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';

interface StoryCardProps {
  story: {
    title: string;
    timestamp: Date;
    articles: Array<{
      title: string;
      description?: string;
      link: string;
      pubDate?: string;
    }>;
  };
}

export function StoryCard({ story }: StoryCardProps) {
  const formatUrl = (url: string) => {
    try {
      const hostname = new URL(url).hostname.replace('www.', '');
      return hostname.length > 30 ? hostname.slice(0, 30) + '...' : hostname;
    } catch {
      return url;
    }
  };

  return (
    <Card className="w-[300px] flex-none snap-start overflow-hidden rounded-xl bg-black/20 backdrop-blur-sm transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,170,0,0.2)] sm:w-[350px] md:w-[400px]">
      <Accordion type="single" collapsible>
        <AccordionItem value="articles" className="border-none">
          <AccordionTrigger className="px-6 py-4 hover:no-underline">
            <div className="flex flex-1 flex-col items-start text-left">
              <h2 className="text-lg font-semibold">{story.title}</h2>
              <div className="mt-2 flex items-center gap-4 text-sm">
                <span className="text-muted-foreground">
                  {format(story.timestamp, 'MMM d, yyyy h:mm a')}
                </span>
                {story.articles.length > 1 && (
                  <>
                    <span className="text-muted-foreground">•</span>
                    <span className="text-[#FFAA00]">
                      {story.articles.length} related articles
                    </span>
                  </>
                )}
              </div>
            </div>
          </AccordionTrigger>

          <AccordionContent>
            <div className="border-t border-border bg-black/20 px-6 py-4">
              <div className="space-y-4">
                {story.articles.map((article) => (
                  <a
                    key={article.link}
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group flex flex-col rounded-lg border border-border bg-black/20 p-4 transition-colors hover:bg-black/40"
                  >
                    <div className="flex flex-col justify-between gap-4">
                      <div>
                        <div className="flex items-start justify-between gap-2">
                          <h3 className="font-medium group-hover:text-[#FFAA00]">
                            {article.title}
                          </h3>
                          <ExternalLink className="h-4 w-4 flex-shrink-0 opacity-0 transition-opacity group-hover:opacity-100" />
                        </div>
                        {article.description && (
                          <p className="mt-2 text-sm text-muted-foreground line-clamp-2">
                            {article.description.replace(/<[^>]*>/g, '')}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-2 text-xs text-muted-foreground">
                        <span>{formatUrl(article.link)}</span>
                        {article.pubDate && (
                          <>
                            <span>•</span>
                            <span>{format(new Date(article.pubDate), 'MMM d, yyyy h:mm a')}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}