import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { useOnboarding } from './OnboardingProvider';
import { OnboardingStep } from './types';
import { Logo } from '@/components/Logo';

interface OnboardingOverlayProps {
  step: OnboardingStep;
}

export function OnboardingOverlay({ step }: OnboardingOverlayProps) {
  const { nextStep, previousStep, skipTutorial, currentStep } = useOnboarding();
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (step.target !== 'center') {
      const element = document.querySelector(step.target) as HTMLElement;
      if (element) {
        setTargetElement(element);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [step]);

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      {/* Solid Background */}
      <div className="absolute inset-0 bg-black/90" />

      {/* Content */}
      <div className="relative z-10 max-w-2xl rounded-2xl bg-card/80 backdrop-blur-sm p-8">
        <div className="mb-8 flex justify-center">
          <Logo size="lg" />
        </div>
        
        <h2 className="mb-4 text-4xl font-bold text-white">{step.title}</h2>
        <p className="mb-8 text-xl leading-relaxed text-white/90">{step.content}</p>
        
        <div className="flex items-center justify-center gap-4">
          <Button
            variant="outline"
            size="lg"
            onClick={previousStep}
            disabled={currentStep === 0}
            className="min-w-[120px] text-lg"
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="lg"
            onClick={nextStep}
            className="min-w-[120px] text-lg"
          >
            {currentStep === 4 ? 'Finish' : 'Next'}
          </Button>
          <Button
            variant="ghost"
            size="lg"
            onClick={skipTutorial}
            className="min-w-[120px] text-lg"
          >
            Skip
          </Button>
        </div>
      </div>

      {/* Highlight target element if exists */}
      {targetElement && (
        <div 
          className="absolute"
          style={{
            top: targetElement.offsetTop - 8,
            left: targetElement.offsetLeft - 8,
            width: targetElement.offsetWidth + 16,
            height: targetElement.offsetHeight + 16,
            boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.75)',
            borderRadius: '8px',
          }}
        >
          <div 
            className="absolute inset-0 rounded-lg border-2 border-primary"
            style={{ 
              boxShadow: '0 0 0 2px rgba(255, 170, 0, 0.1), 0 0 15px rgba(255, 170, 0, 0.2)' 
            }}
          />
        </div>
      )}
    </div>
  );
}