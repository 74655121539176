import { useState, useEffect } from 'react';
import { getFearAndGreedIndex, getMarketMetrics } from '@/lib/api/coinmarketcap';

interface CryptoData {
  marketCap: {
    value: string;
    change: number;
    trend: number[];
  };
  volume: {
    value: string;
    change: number;
    trend: number[];
  };
  fearAndGreed: {
    value: number;
    label: string;
  };
  btcDominance: number;
  ethDominance: number;
}

export function useCryptoData() {
  const [metrics, setMetrics] = useState<CryptoData>({
    marketCap: {
      value: '$0',
      change: 0,
      trend: Array(10).fill(0)
    },
    volume: {
      value: '$0',
      change: 0,
      trend: Array(10).fill(0)
    },
    fearAndGreed: {
      value: 0,
      label: 'Neutral'
    },
    btcDominance: 0,
    ethDominance: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const [fng, marketMetrics] = await Promise.all([
          getFearAndGreedIndex(),
          getMarketMetrics()
        ]);

        setMetrics({
          marketCap: {
            value: marketMetrics.marketCap.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            }),
            change: marketMetrics.marketCap.change,
            trend: Array(10).fill(0).map(() => Math.random() * 100)
          },
          volume: {
            value: marketMetrics.volume.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            }),
            change: marketMetrics.volume.change,
            trend: Array(10).fill(0).map(() => Math.random() * 100)
          },
          fearAndGreed: {
            value: fng.value,
            label: fng.classification
          },
          btcDominance: marketMetrics.btcDominance,
          ethDominance: marketMetrics.ethDominance
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  return { metrics, loading, error };
}