import { format } from 'date-fns';
import { ArticleInteractions } from '../ArticleInteractions';

interface BlogContentProps {
  post: {
    title: string;
    content: string;
    author: string;
    date: string;
    image?: string;
    tags: string[];
    url: string;
  };
}

export function BlogContent({ post }: BlogContentProps) {
  return (
    <article className="mx-auto max-w-3xl space-y-8">
      {post.image && (
        <div className="aspect-video w-full overflow-hidden rounded-xl">
          <img
            src={post.image}
            alt={post.title}
            className="h-full w-full object-cover"
          />
        </div>
      )}

      <div className="space-y-4">
        <h1 className="text-4xl font-bold">{post.title}</h1>
        <div className="flex items-center justify-between text-muted-foreground">
          <span>{post.author}</span>
          <span>{format(new Date(post.date), 'MMMM d, yyyy')}</span>
        </div>
      </div>

      <div className="prose prose-invert max-w-none">
        {post.content.split('\n\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>

      <div className="flex flex-wrap gap-2">
        {post.tags.map((tag) => (
          <span
            key={tag}
            className="rounded-full bg-primary/10 px-3 py-1 text-sm text-primary"
          >
            {tag}
          </span>
        ))}
      </div>

      <div className="mt-8 border-t border-border pt-4">
        <ArticleInteractions articleId={post.url} />
      </div>
    </article>
  );
}