import { Button } from '@/components/ui/button';
import { useOnboarding } from '@/components/onboarding/OnboardingProvider';

export function WelcomeButton() {
  const { startTutorial } = useOnboarding();

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={startTutorial}
      className="hidden lg:flex"
    >
      Welcome Tour
    </Button>
  );
}