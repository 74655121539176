import { create } from 'zustand';

interface User {
  id: string;
  username: string;
  avatar?: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  signInWithX: () => Promise<void>;
  signOut: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  signInWithX: async () => {
    // Mock X authentication
    set({
      user: {
        id: '123',
        username: 'CryptoTrader',
        avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Felix'
      },
      isAuthenticated: true
    });
  },
  signOut: () => {
    set({ user: null, isAuthenticated: false });
  }
}));