import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { FEED_PRESETS, FEED_ORDER } from '@/lib/constants/feeds';

interface SettingsState {
  degenMode: boolean;
  enabledFeeds: string[];
  feedOrder: string[];
  toggleDegenMode: () => void;
  setEnabledFeeds: (feeds: string[]) => void;
}

const defaultState = {
  degenMode: false,
  enabledFeeds: Object.entries(FEED_PRESETS.default)
    .filter(([_, enabled]) => enabled)
    .map(([key]) => key),
  feedOrder: FEED_ORDER.default,
};

export const useSettings = create<SettingsState>()(
  persist(
    (set) => ({
      ...defaultState,
      toggleDegenMode: () =>
        set((state) => {
          const newMode = !state.degenMode;
          const preset = newMode ? FEED_PRESETS.degen : FEED_PRESETS.default;
          const enabledFeeds = Object.entries(preset)
            .filter(([_, enabled]) => enabled)
            .map(([key]) => key);
          const feedOrder = newMode ? FEED_ORDER.degen : FEED_ORDER.default;
          return { degenMode: newMode, enabledFeeds, feedOrder };
        }),
      setEnabledFeeds: (feeds) => set({ enabledFeeds: feeds }),
    }),
    {
      name: 'settings-storage',
      version: 3,
    }
  )
);