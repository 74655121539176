import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { useOnboarding } from './onboarding/OnboardingProvider';

export default function Footer() {
  const { startTutorial } = useOnboarding();
  const currentYear = new Date().getFullYear();

  const sections = {
    quickLinks: [
      { to: "/", label: "Home" },
      { to: "/favorites", label: "Favorites" },
      { to: "/blurts", label: "Blurts" },
      { to: "/blog", label: "Blog" },
      { label: "Welcome Tour", onClick: startTutorial }
    ],
    legal: [
      { to: "/disclaimer", label: "Disclaimer" },
      { to: "/terms", label: "Terms of Service" },
      { to: "/privacy", label: "Privacy Policy" },
      { to: "/contact", label: "Contact Us" }
    ]
  };

  return (
    <footer className="mt-auto border-t border-border bg-background/80 backdrop-blur-sm">
      <div className="container mx-auto px-4 py-8">
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
          <div className="space-y-4">
            <Logo size="sm" />
            <p className="text-sm text-muted-foreground">
              Your trusted source for news and market insights.
            </p>
          </div>
          
          <div>
            <h3 className="mb-4 text-sm font-semibold">Quick Links</h3>
            <ul className="space-y-2 text-sm text-muted-foreground">
              {sections.quickLinks.map(link => (
                <li key={link.label}>
                  {link.to ? (
                    <Link to={link.to} className="hover:text-primary">
                      {link.label}
                    </Link>
                  ) : (
                    <button 
                      onClick={link.onClick} 
                      className="hover:text-primary"
                    >
                      {link.label}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h3 className="mb-4 text-sm font-semibold">Legal</h3>
            <ul className="space-y-2 text-sm text-muted-foreground">
              {sections.legal.map(link => (
                <li key={link.to}>
                  <Link to={link.to} className="hover:text-primary">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="mt-8 flex flex-col items-center justify-between gap-4 border-t border-border pt-8 text-sm text-muted-foreground sm:flex-row">
          <p>© {currentYear} NextCoinNews.com. All rights reserved.</p>
          <div className="flex gap-4">
            <a href="#" className="hover:text-primary">Twitter</a>
            <a href="#" className="hover:text-primary">Discord</a>
            <a href="#" className="hover:text-primary">Telegram</a>
          </div>
        </div>
      </div>
    </footer>
  );
}