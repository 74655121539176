import { Settings, Bell, User, LogIn } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useAuth } from '@/lib/auth';

const userNavigation = [
  { name: 'Profile', href: '/profile', icon: User },
  { name: 'Notifications', href: '/notifications', icon: Bell },
  { name: 'Settings', href: '/settings', icon: Settings },
];

export function UserProfile({ onClose }: { onClose?: () => void }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <div className="border-t border-border p-4">
        <NavLink
          to="/login"
          className="flex w-full items-center justify-center rounded-lg bg-gradient-to-r from-[#FFAA00] to-[#CA5003] px-3 py-2 text-sm font-medium text-white hover:from-[#FFAA00]/90 hover:to-[#CA5003]/90"
          onClick={onClose}
        >
          <LogIn className="mr-2 h-5 w-5" />
          Sign In
        </NavLink>
      </div>
    );
  }

  return (
    <div className="border-t border-border p-4">
      <div className="px-3 py-2 text-xs font-semibold uppercase tracking-wider text-muted-foreground/50">
        User
      </div>
      <div className="space-y-1">
        {userNavigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              cn(
                'flex items-center rounded-lg px-3 py-2 text-sm font-medium',
                isActive
                  ? 'bg-gradient-to-r from-[#FFAA00]/10 to-[#CA5003]/10 text-primary'
                  : 'text-muted-foreground hover:bg-accent hover:text-accent-foreground'
              )
            }
            onClick={onClose}
          >
            <item.icon className="mr-3 h-5 w-5" />
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
}