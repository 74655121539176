import { TrendingUp, TrendingDown } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { BlurtAvatar } from './BlurtAvatar';
import { DiamondIcon } from '@/components/icons/DiamondIcon';
import { calculatePayout } from '@/lib/constants/predictions';

interface PredictionBlurtProps {
  prediction: {
    id: string;
    username: string;
    avatar?: string;
    token: string;
    direction: 'up' | 'down';
    percentage: number;
    timeFrame: string;
    wager: number;
    timestamp: string;
    status: 'active' | 'won' | 'lost';
  };
}

export function PredictionBlurt({ prediction }: PredictionBlurtProps) {
  const isActive = prediction.status === 'active';
  const potentialPayout = calculatePayout(prediction.wager, prediction.percentage);

  return (
    <Card className={cn(
      "overflow-hidden",
      prediction.status === 'won' && "border-green-500/50",
      prediction.status === 'lost' && "border-red-500/50"
    )}>
      <div className="border-b border-border bg-card/50 p-4">
        <div className="flex items-center gap-2">
          <BlurtAvatar username={prediction.username} avatar={prediction.avatar} />
          <div className="flex flex-1 items-baseline gap-2">
            <span className="font-medium">{prediction.username}</span>
            <span className="text-sm text-muted-foreground">
              {formatDistanceToNow(new Date(prediction.timestamp), { addSuffix: true })}
            </span>
          </div>
          {!isActive && (
            <div className={cn(
              "rounded-full px-3 py-1 text-sm font-medium",
              prediction.status === 'won' && "bg-green-500/10 text-green-500",
              prediction.status === 'lost' && "bg-red-500/10 text-red-500"
            )}>
              {prediction.status === 'won' ? 'Won' : 'Lost'}
            </div>
          )}
        </div>
      </div>
      
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-sm text-muted-foreground">Prediction</div>
            <div className="flex items-center gap-2 text-lg font-medium">
              {prediction.token} will go
              {prediction.direction === 'up' ? (
                <TrendingUp className="h-5 w-5 text-green-500" />
              ) : (
                <TrendingDown className="h-5 w-5 text-red-500" />
              )}
              {prediction.percentage}%
            </div>
            <div className="mt-1 text-sm text-muted-foreground">
              in {prediction.timeFrame}
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm text-muted-foreground">
              {isActive ? 'Potential Win' : 'Wager'}
            </div>
            <div className="flex items-center gap-1 text-lg font-bold text-primary">
              <DiamondIcon className="h-4 w-4" />
              <span>{isActive ? potentialPayout.toLocaleString() : prediction.wager.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}