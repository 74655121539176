import { MessageSquare } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { Card } from '@/components/ui/card';
import { ArticleCard } from '@/components/ArticleCard';
import { BlurtAvatar } from './BlurtAvatar';
import { cn } from '@/lib/utils';

interface CommentBlurtProps {
  comment: {
    id: string;
    username: string;
    content: string;
    timestamp: Date;
  };
  article?: any;
}

export function CommentBlurt({ comment, article }: CommentBlurtProps) {
  return (
    <Card className="p-6">
      <div className="flex flex-col gap-6 lg:flex-row">
        <div className="flex-1 space-y-4">
          <div className="flex items-center gap-2">
            <BlurtAvatar username={comment.username} />
            <span className="font-medium text-foreground">
              {comment.username}
            </span>
            <span className="text-sm text-muted-foreground">
              commented {formatDistanceToNow(comment.timestamp, { addSuffix: true })}
            </span>
          </div>

          <div className="rounded-lg border border-border bg-muted/50 p-4">
            <p className="text-sm text-muted-foreground">
              {comment.content}
            </p>
          </div>
        </div>

        {article && (
          <div className="w-full lg:w-[400px]">
            <ArticleCard
              article={article}
              isHidden={false}
              onHide={() => {}}
            />
          </div>
        )}
      </div>
    </Card>
  );
}