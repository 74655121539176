export const initialBlogPosts = [
  {
    id: '1',
    url: '/blog/bitcoin-halving-impact',
    title: 'Bitcoin Halving 2024: The Aftermath',
    content: `As we reflect on the Bitcoin halving that occurred in April 2024, its impact on the market has been nothing short of remarkable. The reduction in mining rewards from 6.25 to 3.125 BTC triggered a series of events that shaped the crypto landscape throughout the year.

The post-halving period saw Bitcoin's price action follow historical patterns, though with some unique characteristics. Unlike previous cycles, institutional involvement played a much larger role, with several major financial institutions increasing their Bitcoin holdings in response to the reduced supply.

The mining industry underwent significant consolidation, with smaller operations being absorbed by larger players who could maintain profitability despite the reduced block rewards. This has led to interesting discussions about network centralization and long-term sustainability.`,
    excerpt: 'Analyzing the market impact and industry changes following the 2024 Bitcoin halving.',
    author: 'TommyTwoClubs',
    date: '2024-12-15',
    tags: ['Bitcoin', 'Halving', 'Cryptocurrency', 'Mining'],
    image: 'https://images.unsplash.com/photo-1518546305927-5a555bb7020d?w=800&auto=format&fit=crop'
  },
  {
    id: '2',
    url: '/blog/solana-2024-review',
    title: 'Solana in 2024: Year of Mainstream Adoption',
    content: `Looking back at Solana's journey through 2024, it's clear that this has been a transformative year for the ecosystem. The network's stability improvements and strategic partnerships have paid off, leading to unprecedented mainstream adoption.

The success of Solana's mobile strategy, particularly with the Saga phone and Mobile Stack, has brought crypto functionality to millions of new users. DeFi protocols on Solana have seen record volumes, while the gaming and NFT sectors continue to flourish.

What's particularly noteworthy is how Solana has maintained its high performance and low fees despite the massive increase in network activity. The ecosystem's maturity is evident in the quality of new projects and the depth of liquidity across various protocols.`,
    excerpt: 'Reviewing Solana\'s remarkable year of growth and mainstream adoption milestones.',
    author: 'CrayHans',
    date: '2024-12-05',
    tags: ['Solana', 'DeFi', 'Blockchain', 'Cryptocurrency'],
    image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?w=800&auto=format&fit=crop'
  },
  {
    id: '3',
    url: '/blog/memecoin-evolution-2024',
    title: 'Memecoins 2024: From Jokes to Genuine Utility',
    content: `The memecoin landscape has undergone a fascinating evolution in 2024. What started as simple joke tokens have, in some cases, transformed into projects with genuine utility and engaged communities.

This year's standout development has been the emergence of "utility memes" - memecoins that combine community engagement with actual use cases. From governance tokens to gaming assets, these projects are redefining what's possible in the memecoin space.

However, it's important to note that not all memecoins have made this transition successfully. The market has become more discerning, with projects needing to offer real value beyond mere speculation to maintain relevance.`,
    excerpt: 'How memecoins evolved from pure speculation to utility-driven projects in 2024.',
    author: 'CrayHans',
    date: '2024-11-28',
    tags: ['Memecoins', 'Dogecoin', 'Cryptocurrency', 'Trading'],
    image: 'https://images.unsplash.com/photo-1621504450181-5d356f61d307?w=800&auto=format&fit=crop'
  },
  {
    id: '4',
    url: '/blog/crypto-market-2024-recap',
    title: 'Crypto Market 2024: Year in Review',
    content: `As we conclude 2024, it's time to analyze the major developments that shaped the cryptocurrency market this year. From regulatory clarity to technological breakthroughs, this has been a pivotal year for the industry.

The approval and launch of spot Bitcoin ETFs marked a significant milestone, while the successful implementation of various Layer 2 scaling solutions has addressed many of the scalability concerns that plagued previous years.

Institutional adoption has reached new heights, with major banks and investment firms not just offering crypto services, but actively participating in DeFi protocols. The regulatory landscape has also evolved, with clearer frameworks emerging in key markets.`,
    excerpt: 'A comprehensive analysis of the cryptocurrency market\'s major developments in 2024.',
    author: 'TommyTwoClubs',
    date: '2024-12-30',
    tags: ['Cryptocurrency', 'Market Analysis', 'Trends', 'Bitcoin'],
    image: 'https://images.unsplash.com/photo-1605792657660-596af9009e82?w=800&auto=format&fit=crop'
  }
];