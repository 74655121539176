import { formatDistanceToNow } from 'date-fns';
import { MessageSquare, Repeat2, Heart, Share, MoreHorizontal, BadgeCheck } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';

interface XPostProps {
  tweet: any;
  className?: string;
}

export function XPost({ tweet, className }: XPostProps) {
  const stripHtml = (html: string) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      return 'recently';
    }
  };

  const stats = {
    replies: Math.floor(Math.random() * 100),
    retweets: Math.floor(Math.random() * 200),
    likes: Math.floor(Math.random() * 1000),
  };

  const isVerified = Math.random() > 0.7;

  return (
    <Card className={cn("overflow-hidden bg-card hover:bg-card/80", className)}>
      <div className="p-4">
        {/* Header */}
        <div className="flex items-start justify-between gap-3">
          <Avatar className="h-10 w-10 ring-2 ring-primary ring-offset-2 ring-offset-background">
            <AvatarImage src={tweet.image} alt={tweet.creator} />
            <AvatarFallback>
              {tweet.creator?.slice(0, 2).toUpperCase() || 'AN'}
            </AvatarFallback>
          </Avatar>
          
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-baseline gap-2 min-w-0">
                <div className="flex items-center gap-1">
                  <span className="font-bold truncate hover:underline">
                    {tweet.creator || 'Anonymous'}
                  </span>
                  {isVerified && (
                    <BadgeCheck className="h-4 w-4 text-primary" />
                  )}
                </div>
                <span className="text-sm text-muted-foreground truncate">
                  @{tweet.creator?.toLowerCase().replace(/\s/g, '') || 'anonymous'}
                </span>
                <span className="text-sm text-muted-foreground">·</span>
                <span className="text-sm text-muted-foreground hover:underline">
                  {formatDate(tweet.pubDate)}
                </span>
              </div>
              <Button variant="ghost" size="icon" className="h-8 w-8 text-muted-foreground hover:text-primary">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="mt-3 space-y-3">
          <p className="whitespace-pre-wrap break-words text-[15px] leading-normal">
            {stripHtml(tweet.description)}
          </p>
          
          {tweet.media?.content && (
            <div className="mt-3 overflow-hidden rounded-2xl border border-border">
              <img
                src={tweet.media.content}
                alt=""
                className="h-full w-full object-cover"
                loading="lazy"
              />
            </div>
          )}
        </div>

        {/* Actions */}
        <div className="mt-3 flex justify-between text-muted-foreground">
          <Button 
            variant="ghost" 
            size="sm" 
            className="hover:text-primary group"
          >
            <MessageSquare className="mr-2 h-4 w-4 transition-colors group-hover:text-primary" />
            <span className="transition-colors group-hover:text-primary">
              {stats.replies.toLocaleString()}
            </span>
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            className="hover:text-green-500 group"
          >
            <Repeat2 className="mr-2 h-4 w-4 transition-colors group-hover:text-green-500" />
            <span className="transition-colors group-hover:text-green-500">
              {stats.retweets.toLocaleString()}
            </span>
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            className="hover:text-red-500 group"
          >
            <Heart className="mr-2 h-4 w-4 transition-colors group-hover:text-red-500" />
            <span className="transition-colors group-hover:text-red-500">
              {stats.likes.toLocaleString()}
            </span>
          </Button>
          <Button 
            variant="ghost" 
            size="sm" 
            className="hover:text-primary group"
          >
            <Share className="h-4 w-4 transition-colors group-hover:text-primary" />
          </Button>
        </div>
      </div>
    </Card>
  );
}