import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCommentStore, usePredictionStore, useFeedStore } from '@/lib/store';
import { usePredictionChecker } from '@/hooks/use-prediction-checker';
import { BlurtsHeader } from '@/components/blurts/BlurtsHeader';
import { BlurtsFilter } from '@/components/blurts/BlurtsFilter';
import { BlurtsTimeline } from '@/components/blurts/BlurtsTimeline';

export default function Blurts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<'all' | 'comments' | 'predictions' | 'active' | 'completed'>(
    (searchParams.get('filter') as any) || 'all'
  );
  const [sort, setSort] = useState<'newest' | 'winning' | 'losing'>('newest');
  
  const { comments } = useCommentStore();
  const { predictions } = usePredictionStore();
  const { feeds } = useFeedStore();

  // Use the prediction checker hook
  usePredictionChecker();

  // Update URL when filter changes
  useEffect(() => {
    if (filter === 'all') {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', filter);
    }
    setSearchParams(searchParams);
  }, [filter, searchParams, setSearchParams]);

  const allArticles = Object.values(feeds).flat();

  const timeline = [
    ...comments.map(comment => ({
      type: 'comment' as const,
      data: comment,
      timestamp: comment.timestamp,
      article: allArticles.find(a => a.id === comment.articleId),
    })),
    ...predictions.map(prediction => ({
      type: 'prediction' as const,
      data: prediction,
      timestamp: new Date(prediction.timestamp),
    }))
  ];

  // Apply filters and sorting
  const sortedAndFilteredTimeline = timeline
    .filter(item => {
      switch (filter) {
        case 'comments':
          return item.type === 'comment';
        case 'predictions':
          return item.type === 'prediction';
        case 'active':
          return item.type === 'prediction' && item.data.status === 'active';
        case 'completed':
          return item.type === 'prediction' && item.data.status !== 'active';
        default:
          return true;
      }
    })
    .sort((a, b) => {
      if (sort === 'newest') {
        return b.timestamp.getTime() - a.timestamp.getTime();
      }
      
      // Only apply win/loss sorting to predictions
      if (a.type === 'prediction' && b.type === 'prediction') {
        if (sort === 'winning') {
          return (b.data.status === 'won' ? 1 : -1) - (a.data.status === 'won' ? 1 : -1);
        }
        if (sort === 'losing') {
          return (b.data.status === 'lost' ? 1 : -1) - (a.data.status === 'lost' ? 1 : -1);
        }
      }
      
      return b.timestamp.getTime() - a.timestamp.getTime();
    });

  return (
    <div className="space-y-8 pt-8">
      <BlurtsHeader />
      <BlurtsFilter 
        filter={filter} 
        sort={sort}
        onFilterChange={setFilter}
        onSortChange={setSort}
      />
      <BlurtsTimeline items={sortedAndFilteredTimeline} />
    </div>
  );
}