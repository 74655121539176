export const FEED_URLS = {
  bitcoin: 'https://rss.app/feeds/_HpDwqOt2KcwoLSoX.json',
  nextcoinnews: 'https://rss.app/feeds/_Hk4ipDrPdyxR5OiK.xml',
  news: 'https://rss.app/feeds/_HpDwqOt2KcwoLSoX.json',
  market: 'https://rss.app/feeds/_2JHkJmCr5N3R0tin.xml',
  search: 'https://rss.app/feeds/_VGJUutruPHhFZoDk.xml',
  xfeed: 'https://rss.app/feeds/_GCTyu2hjdrYeS0ro.xml',
  nftart: 'https://rss.app/feeds/_iXOn83T3jWcAqxOM.xml',
  instagram: 'https://rss.app/feeds/_dHJ74cpg6yd41Cqi.xml',
  tiktok: 'https://rss.app/feeds/_RzIpPUPxV5agJIii.xml',
  youtube: 'https://rss.app/feeds/_QMNSWWasV0pYiplZ.xml',
  animalmemes: 'https://rss.app/feeds/_TPRWBDHmKVslJDDK.xml'
} as const;

export const FEED_NAMES = {
  bitcoin: 'Bitcoin News',
  nextcoinnews: 'NextCoinNews',
  news: 'Latest News',
  market: 'Market Analysis',
  search: 'Targeted Search',
  xfeed: 'X Feed',
  nftart: 'NFT/Art',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  youtube: 'YouTube',
  animalmemes: 'Animal Memes'
} as const;

export const FEED_KEYWORDS = {
  bitcoin: ['Bitcoin (BTC)', 'Bitcoin Technical Analysis', 'BTC/SOL'],
  nextcoinnews: ['NextCoinNews', 'Crypto News', 'Market Updates'],
  market: ['Solana (SOL)', 'Ethereum (ETH)', 'Altcoins'],
  news: ['Blockchain', 'Memecoins'],
  search: ['Bitcoin (BTC)', 'Solana (SOL)', 'Ethereum (ETH)'],
  xfeed: ['Altcoins', 'Memecoins', 'Blockchain'],
  nftart: ['NFTs', 'Digital Art', 'Blockchain'],
  instagram: ['Crypto News', 'Market Updates', 'Trading'],
  tiktok: ['Crypto Trends', 'Trading Tips', 'Market Analysis'],
  youtube: ['Technical Analysis', 'Market Research', 'Crypto Education'],
  animalmemes: ['Memes', 'Animal Tokens', 'Community']
} as const;

export const FEED_PRESETS = {
  default: {
    bitcoin: true,
    nextcoinnews: true,
    news: true,
    market: true,
    search: true,
    xfeed: false,
    nftart: false,
    instagram: false,
    tiktok: false,
    youtube: false,
    animalmemes: false
  },
  degen: {
    bitcoin: true,
    nextcoinnews: true,
    news: true,
    market: true,
    search: true,
    xfeed: true,
    nftart: true,
    instagram: true,
    tiktok: true,
    youtube: true,
    animalmemes: true
  }
} as const;

export const FEED_ORDER = {
  default: ['nextcoinnews', 'bitcoin', 'news', 'market', 'search'],
  degen: ['nextcoinnews', 'animalmemes', 'bitcoin', 'news', 'market', 'search', 'xfeed', 'nftart', 'instagram', 'tiktok', 'youtube']
} as const;

export const sections = [
  { key: 'nextcoinnews', title: FEED_NAMES.nextcoinnews },
  { key: 'bitcoin', title: FEED_NAMES.bitcoin },
  { key: 'news', title: FEED_NAMES.news },
  { key: 'market', title: FEED_NAMES.market },
  { key: 'search', title: FEED_NAMES.search },
  { key: 'xfeed', title: FEED_NAMES.xfeed },
  { key: 'nftart', title: FEED_NAMES.nftart },
  { key: 'instagram', title: FEED_NAMES.instagram },
  { key: 'tiktok', title: FEED_NAMES.tiktok },
  { key: 'youtube', title: FEED_NAMES.youtube },
  { key: 'animalmemes', title: FEED_NAMES.animalmemes }
];