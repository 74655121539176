import { cn } from "@/lib/utils";

interface LogoProps {
  className?: string;
  size?: "sm" | "md" | "lg";
  asLink?: boolean;
}

export function Logo({ className, size = "md", asLink = false }: LogoProps) {
  const sizes = {
    sm: "h-6",
    md: "h-8",
    lg: "h-10"
  };

  const content = (
    <div className={cn("flex items-center gap-2", className)}>
      <img 
        src="https://i.ibb.co/XkDMRb8/nextcoinnewsicon.png" 
        alt="NextCoinNews" 
        className={cn(sizes[size], "w-auto")}
      />
      <span className="font-sans font-extrabold leading-none text-white">
        <div className={cn(
          "text-lg",
          size === "sm" && "text-base",
          size === "lg" && "text-2xl"
        )}>
          NextCoinNews
        </div>
      </span>
    </div>
  );

  return content;
}