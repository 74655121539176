export const TIME_FRAMES = [
  { value: "5m", label: "5m" },
  { value: "10m", label: "10m" },
  { value: "30m", label: "30m" },
  { value: "1h", label: "1h" }
] as const;

export const PERCENTAGES = [
  { value: 0.5, label: "0.5%" },
  { value: 1, label: "1%" },
  { value: 2, label: "2%" },
  { value: 3, label: "3%" }
] as const;

export function calculatePayout(wager: number, percentage: number): number {
  // Payout = wager + (wager * percentage)
  // Example: 100 wager at 2% = 100 + (100 * 2) = 300
  return wager + (wager * percentage);
}