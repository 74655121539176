import { cn } from '@/lib/utils';
import { Users, Rat, Copy, Shield, Target } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useToast } from '@/hooks/use-toast';

interface TokenStats {
  volume: string;
  marketCap: string;
  holders: number;
  insiderHoldings: number;
  comments: number;
}

interface TokenCardProps {
  symbol: string;
  name: string;
  time: string;
  redPercent: number;   // Top 10 Holders
  greenPercent: number; // Dev Holdings
  bluePercent: number;  // Sniper Holdings
  stats: TokenStats;
  contractAddress?: string;
}

export function PumpVisionCard({
  symbol,
  name,
  time,
  redPercent,
  greenPercent,
  bluePercent,
  stats,
  contractAddress = "sample-contract-address"
}: TokenCardProps) {
  const { toast } = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    toast({
      title: "Contract Address Copied",
      description: "Token contract address copied to clipboard"
    });
  };

  return (
    <div className="rounded-lg border border-border bg-card/50 p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-muted">
            <span className="text-sm font-semibold">{symbol[0]}</span>
          </div>
          <div>
            <div className="flex items-center gap-2">
              <button 
                onClick={handleCopy}
                className="flex items-center gap-1 font-medium hover:text-primary"
              >
                {symbol}
                <Copy className="h-3 w-3 opacity-50" />
              </button>
              <button 
                onClick={handleCopy}
                className="text-sm text-muted-foreground hover:text-primary"
              >
                ({name})
              </button>
            </div>
            <div className="flex items-center gap-2 text-xs">
              <span>{time}</span>
              <div className="flex items-center gap-1">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-1">
                        <Users className="h-3 w-3" />
                        <span className="text-red-500">{redPercent}%</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Top 10 Holdings</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-1">
                        <Shield className="h-3 w-3" />
                        <span className="text-green-500">{greenPercent}%</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Dev Holdings</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-1">
                        <Target className="h-3 w-3" />
                        <span className="text-blue-500">{bluePercent}%</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Sniper Holdings</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button className="rounded-full bg-primary/10 px-3 py-1 text-xs font-medium text-primary">
            $5
          </button>
        </div>
      </div>

      <div className="mt-2 flex flex-col gap-1 border-t border-border pt-2 text-xs text-muted-foreground">
        <div className="flex items-center justify-between">
          <span>V: {stats.volume}</span>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Users className="h-3 w-3" />
              <span>{stats.holders}</span>
            </div>
            <div className="flex items-center gap-1">
              <Rat className="h-3 w-3" />
              <span>{stats.insiderHoldings}</span>
            </div>
            <div className="flex items-center gap-1">
              <span>{stats.comments}</span>
            </div>
          </div>
        </div>
        <div>
          <span>MC: {stats.marketCap}</span>
        </div>
      </div>
    </div>
  );
}