import { PumpVisionCard } from './PumpVisionCard';
import { cn } from '@/lib/utils';

interface PumpVisionSectionProps {
  id: string;
  tokens: any[];
  className?: string;
}

export function PumpVisionSection({ 
  id, 
  tokens,
  className 
}: PumpVisionSectionProps) {
  return (
    <div className={cn("space-y-4", className)}>
      <div className="grid max-h-[calc(100vh-300px)] gap-4 overflow-y-auto pr-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-primary/20 hover:scrollbar-thumb-primary/40">
        {tokens.map((token) => (
          <PumpVisionCard key={`${id}-${token.symbol}`} {...token} />
        ))}
      </div>
    </div>
  );
}