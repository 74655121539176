import { useState } from 'react';
import { ThumbsUp, ThumbsDown, Share, Brain } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { cn } from '@/lib/utils';

interface ArticleActionsProps {
  articleId: string;
  onAnalyze?: () => void;
}

export function ArticleActions({ articleId, onAnalyze }: ArticleActionsProps) {
  const [votes, setVotes] = useState({ up: 0, down: 0 });
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null);
  const { toast } = useToast();

  const handleVote = (type: 'up' | 'down') => {
    if (userVote === type) {
      // Remove vote
      setVotes(prev => ({
        ...prev,
        [type]: prev[type] - 1
      }));
      setUserVote(null);
    } else {
      // Add/change vote
      setVotes(prev => ({
        ...prev,
        [type]: prev[type] + 1,
        ...(userVote && { [userVote]: prev[userVote] - 1 })
      }));
      setUserVote(type);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Check out this article',
        url: window.location.href
      });
    } catch {
      // Fallback to copying URL
      navigator.clipboard.writeText(window.location.href);
      toast({
        title: 'Link copied',
        description: 'Article link copied to clipboard'
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleVote('up')}
          className={cn(
            "hover:text-green-500",
            userVote === 'up' && "text-green-500"
          )}
        >
          <ThumbsUp className="mr-1 h-4 w-4" />
          <span>{votes.up}</span>
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleVote('down')}
          className={cn(
            "hover:text-red-500",
            userVote === 'down' && "text-red-500"
          )}
        >
          <ThumbsDown className="mr-1 h-4 w-4" />
          <span>{votes.down}</span>
        </Button>
      </div>

      <div className="flex items-center gap-1">
        <Button
          variant="ghost"
          size="sm"
          onClick={handleShare}
          className="hover:text-primary"
        >
          <Share className="h-4 w-4" />
        </Button>
        {onAnalyze && (
          <Button
            variant="ghost"
            size="sm"
            onClick={onAnalyze}
            className="hover:text-primary"
          >
            <Brain className="h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}