import { Card } from '@/components/ui/card';
import { FearAndGreedGauge } from './FearAndGreedGauge';
import { DominanceChart } from './DominanceChart';
import { MarketMetric } from './MarketMetric';
import { useCryptoData } from '@/hooks/use-crypto-data';

export function MarketMetrics() {
  const { metrics, loading } = useCryptoData();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid gap-4 sm:grid-cols-2">
      <MarketMetric
        title="Market Cap"
        value={metrics.marketCap.value}
        change={metrics.marketCap.change}
        trend={metrics.marketCap.trend}
      />
      <MarketMetric
        title="Volume"
        value={metrics.volume.value}
        change={metrics.volume.change}
        trend={metrics.volume.trend}
      />
      <FearAndGreedGauge 
        value={metrics.fearAndGreed.value} 
        label={metrics.fearAndGreed.label} 
      />
      <DominanceChart
        data={[
          { symbol: "BTC", value: metrics.btcDominance },
          { symbol: "ETH", value: metrics.ethDominance },
        ]}
      />
    </div>
  );
}