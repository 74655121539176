import { useParams } from 'react-router-dom';
import { useBlogStore } from '@/lib/store/blog-store';
import { BlogContent } from '@/components/blog/BlogContent';

export default function BlogPost() {
  const { slug } = useParams();
  const { posts } = useBlogStore();
  const post = posts.find(p => p.url === `/blog/${slug}`);

  if (!post) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <p className="text-lg text-muted-foreground">Post not found</p>
      </div>
    );
  }

  return (
    <div className="py-8">
      <BlogContent post={post} />
    </div>
  );
}