import { useMemo } from 'react';
import { useFeedStore } from '@/lib/store';
import { useSettings } from '@/hooks/use-settings';
import { FEED_NAMES } from '@/lib/constants/feeds';

export function useDashboardFeeds() {
  const { feeds } = useFeedStore();
  const { enabledFeeds, feedOrder } = useSettings();

  const feedSections = useMemo(() => 
    feedOrder
      .filter(key => enabledFeeds.includes(key) && key !== 'nextcoinnews') // Exclude nextcoinnews
      .map(key => ({
        key,
        title: FEED_NAMES[key as keyof typeof FEED_NAMES],
        articles: feeds[key] || [],
        priority: key === 'bitcoin'
      }))
  , [feeds, enabledFeeds, feedOrder]);

  return { feedSections };
}