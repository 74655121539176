import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface SuggestedTickerProps {
  ticker: string;
  className?: string;
}

export function SuggestedTicker({ ticker, className }: SuggestedTickerProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/token/${ticker.toLowerCase()}`);
  };

  return (
    <Button
      variant="ghost"
      className={cn(
        "rounded-full bg-primary/10 px-3 py-1 text-xs font-medium text-primary hover:bg-primary/20",
        className
      )}
      onClick={handleClick}
    >
      ${ticker}
    </Button>
  );
}