import { create } from 'zustand';

interface User {
  id: string;
  username: string;
  email?: string;
  avatar?: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  signInWithX: () => Promise<void>;
  signOut: () => void;
  updateUser: (updates: Partial<User>) => void;
}

export const useAuth = create<AuthState>((set) => ({
  user: {
    id: '123',
    username: 'CryptoTrader',
    avatar: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IiM0QjU1NjMiLz48cGF0aCBkPSJNMTAwIDEyMEM4MC41OTAxIDEyMCA2NSAxMDQuNDEgNjUgODVDNjUgNjUuNTkwMSA4MC41OTAxIDUwIDEwMCA1MEMxMTkuNDEgNTAgMTM1IDY1LjU5MDEgMTM1IDg1QzEzNSAxMDQuNDEgMTE5LjQxIDEyMCAxMDAgMTIwWiIgZmlsbD0iI0Q5RDlEOSIvPjxwYXRoIGQ9Ik0xNjUgMTcwQzE2NSAxNDIuMzg2IDE0Mi42MTQgMTIwIDExNSAxMjBIODVDNTcuMzg1OCAxMjAgMzUgMTQyLjM4NiAzNSAxNzBIMTY1WiIgZmlsbD0iI0Q5RDlEOSIvPjwvc3ZnPg=='
  },
  isAuthenticated: true,
  signInWithX: async () => {
    set({
      user: {
        id: '123',
        username: 'CryptoTrader',
        avatar: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IiM0QjU1NjMiLz48cGF0aCBkPSJNMTAwIDEyMEM4MC41OTAxIDEyMCA2NSAxMDQuNDEgNjUgODVDNjUgNjUuNTkwMSA4MC41OTAxIDUwIDEwMCA1MEMxMTkuNDEgNTAgMTM1IDY1LjU5MDEgMTM1IDg1QzEzNSAxMDQuNDEgMTE5LjQxIDEyMCAxMDAgMTIwWiIgZmlsbD0iI0Q5RDlEOSIvPjxwYXRoIGQ9Ik0xNjUgMTcwQzE2NSAxNDIuMzg2IDE0Mi42MTQgMTIwIDExNSAxMjBIODVDNTcuMzg1OCAxMjAgMzUgMTQyLjM4NiAzNSAxNzBIMTY1WiIgZmlsbD0iI0Q5RDlEOSIvPjwvc3ZnPg=='
      },
      isAuthenticated: true
    });
  },
  signOut: () => {
    set({ user: null, isAuthenticated: false });
  },
  updateUser: (updates) => set((state) => ({
    user: state.user ? { ...state.user, ...updates } : null
  }))
}));