import { format } from 'date-fns';
import { Calendar, MapPin, Link as LinkIcon } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import type { NotableEvent } from '@/lib/types/event';

interface EventCardProps {
  event: NotableEvent;
  className?: string;
}

export function EventCard({ event, className }: EventCardProps) {
  return (
    <a
      href={event.website}
      target="_blank"
      rel="noopener noreferrer"
      className="block"
    >
      <Card className={cn(
        "group relative flex h-[140px] w-[360px] transform overflow-hidden bg-card/50 transition-all duration-300 hover:scale-[1.02] hover:shadow-lg hover:shadow-primary/10",
        className
      )}>
        {event.imageUrl && (
          <div className="relative h-full w-[140px] flex-shrink-0 overflow-hidden">
            <img 
              src={event.imageUrl} 
              alt={event.title}
              className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-black/20 to-transparent" />
          </div>
        )}

        <div className="flex flex-1 flex-col justify-between p-4">
          <div>
            <h3 className="mb-2 line-clamp-1 text-base font-semibold group-hover:text-primary">
              {event.title}
            </h3>
            
            <div className="space-y-1 text-xs text-muted-foreground">
              <div className="flex items-center gap-2">
                <Calendar className="h-3 w-3" />
                <span>
                  {format(new Date(event.startDate), 'MMM d')} - {format(new Date(event.endDate), 'MMM d, yyyy')}
                </span>
              </div>
              
              <div className="flex items-center gap-2">
                <MapPin className="h-3 w-3" />
                <span>{event.location.city}, {event.location.country}</span>
              </div>

              <div className="flex items-center gap-2">
                <LinkIcon className="h-3 w-3" />
                <span className="truncate opacity-0 transition-opacity group-hover:opacity-100">
                  {new URL(event.website).hostname}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </a>
  );
}