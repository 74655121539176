import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useFeedSettings } from '@/lib/store/feed-settings';
import { useFeedStore } from '@/lib/store';
import { FEED_NAMES } from '@/lib/constants/feeds';

interface EditFeedsModalProps {
  open: boolean;
  onClose: () => void;
}

export function EditFeedsModal({ open, onClose }: EditFeedsModalProps) {
  const { enabledFeeds, setEnabledFeeds } = useFeedSettings();
  const { fetchFeeds } = useFeedStore();
  const [selectedFeeds, setSelectedFeeds] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (open) {
      setSelectedFeeds(enabledFeeds);
      setHasChanges(false);
    }
  }, [enabledFeeds, open]);

  const handleToggleFeed = (feedId: string) => {
    // Don't allow disabling Bitcoin News
    if (feedId === 'bitcoin') return;

    setSelectedFeeds(prev => {
      const newFeeds = prev.includes(feedId)
        ? prev.filter(id => id !== feedId)
        : [...prev, feedId];
      setHasChanges(true);
      return newFeeds;
    });
  };

  const handleSave = async () => {
    setEnabledFeeds(selectedFeeds);
    await fetchFeeds(); // Refresh feeds after updating settings
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="flex max-h-[85vh] flex-col sm:max-w-[425px]">
        <DialogHeader className="border-b border-border px-6 py-4">
          <DialogTitle>Edit RSS Feeds</DialogTitle>
        </DialogHeader>

        <ScrollArea className="flex-1 px-6 py-4">
          <div className="space-y-4">
            {Object.entries(FEED_NAMES).map(([id, name]) => (
              <div
                key={id}
                className="flex items-center justify-between rounded-lg border border-border p-4"
              >
                <div className="flex-1">
                  <span className="font-medium">{name}</span>
                  {id === 'bitcoin' && (
                    <span className="ml-2 text-sm text-muted-foreground">
                      (Always enabled)
                    </span>
                  )}
                </div>
                <Switch
                  checked={selectedFeeds.includes(id)}
                  onCheckedChange={() => handleToggleFeed(id)}
                  disabled={id === 'bitcoin'}
                />
              </div>
            ))}
          </div>
        </ScrollArea>

        {hasChanges && (
          <div className="border-t border-border px-6 py-4">
            <div className="flex justify-end gap-2">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                className="bg-gradient-to-r from-yellow-500 to-green-500 text-white hover:from-yellow-600 hover:to-green-600"
              >
                Save Changes
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}