import { Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DegenModeToggle } from '@/components/mode-toggle/DegenModeToggle';
import { useState } from 'react';
import { EditFeedsModal } from '@/components/feeds/EditFeedsModal';
import { useFeedStore } from '@/lib/store';
import { WelcomeButton } from './WelcomeButton';

interface FeedControlsProps {
  onSearchClick: () => void;
}

export function FeedControls({ onSearchClick }: FeedControlsProps) {
  const [showEditFeeds, setShowEditFeeds] = useState(false);
  const { fetchFeeds } = useFeedStore();

  const handleDegenModeToggle = () => {
    setTimeout(fetchFeeds, 0);
  };

  return (
    <div className="ml-auto flex flex-wrap items-center gap-2">
      <div className="hidden md:flex md:items-center md:gap-4">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={onSearchClick}
        >
          <Search className="h-4 w-4" />
        </Button>
        <DegenModeToggle onToggle={handleDegenModeToggle} />
        <WelcomeButton />
      </div>

      <div className="flex items-center gap-2 md:hidden">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={onSearchClick}
        >
          <Search className="h-4 w-4" />
        </Button>
        <DegenModeToggle compact onToggle={handleDegenModeToggle} />
      </div>

      <EditFeedsModal 
        open={showEditFeeds}
        onClose={() => setShowEditFeeds(false)}
      />
    </div>
  );
}