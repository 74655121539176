import { create } from 'zustand';
import { XMLParser } from 'fast-xml-parser';
import { FEED_URLS } from '@/lib/constants/feeds';
import { useSettings } from '@/hooks/use-settings';
import { processRSSItem } from '@/lib/utils/feed';

const parser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: "@_",
  allowBooleanAttributes: true,
});

async function fetchFeed(url: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const text = await response.text();
    let data;

    // Try parsing as JSON first
    try {
      data = JSON.parse(text);
      // If it's JSON, return items directly
      return Array.isArray(data.items) ? data.items.map(processRSSItem) : [data].map(processRSSItem);
    } catch {
      // If JSON parsing fails, try XML
      data = parser.parse(text);
      const channel = data.rss?.channel;
      if (!channel) {
        console.error('Invalid feed structure:', url);
        return [];
      }

      const items = Array.isArray(channel.item) ? channel.item : [channel.item];
      return items.map(processRSSItem).filter(Boolean);
    }
  } catch (error) {
    console.error('Error fetching feed:', url, error);
    return [];
  }
}

interface FeedState {
  feeds: Record<string, any[]>;
  isLoading: boolean;
  error: string | null;
  favorites: string[];
  fetchFeeds: () => Promise<void>;
  toggleFavorite: (articleId: string) => void;
}

export const useFeedStore = create<FeedState>((set) => ({
  feeds: {},
  isLoading: false,
  error: null,
  favorites: [],
  fetchFeeds: async () => {
    set({ isLoading: true, error: null });
    try {
      const { enabledFeeds } = useSettings.getState();
      
      const feedResults = await Promise.allSettled(
        enabledFeeds
          .filter(key => FEED_URLS[key as keyof typeof FEED_URLS])
          .map(async (key) => {
            const url = FEED_URLS[key as keyof typeof FEED_URLS];
            const items = await fetchFeed(url);
            return [key, items];
          })
      );

      const feeds = feedResults.reduce((acc, result, index) => {
        const key = enabledFeeds[index];
        if (result.status === 'fulfilled') {
          acc[key] = result.value[1];
        } else {
          console.error(`Failed to fetch ${key}:`, result.reason);
          acc[key] = [];
        }
        return acc;
      }, {} as Record<string, any[]>);

      set({ feeds, isLoading: false });
    } catch (error) {
      console.error('Feed fetch error:', error);
      set({ 
        error: 'Failed to fetch feeds. Please try again later.',
        isLoading: false 
      });
    }
  },
  toggleFavorite: (articleId) =>
    set((state) => ({
      favorites: state.favorites.includes(articleId)
        ? state.favorites.filter((id) => id !== articleId)
        : [...state.favorites, articleId],
    })),
}));