import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/hooks/use-toast';
import type { NotableEvent } from '@/lib/types/event';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface EventFormProps {
  initialData?: NotableEvent;
  onSubmit: (data: Omit<NotableEvent, 'id'>) => void;
}

// Top cities for crypto events
const POPULAR_LOCATIONS = [
  { city: 'Miami', country: 'United States' },
  { city: 'Dubai', country: 'UAE' },
  { city: 'Singapore', country: 'Singapore' },
  { city: 'London', country: 'United Kingdom' },
  { city: 'Hong Kong', country: 'China' },
  { city: 'Seoul', country: 'South Korea' },
  { city: 'Tokyo', country: 'Japan' },
  { city: 'Paris', country: 'France' },
  { city: 'Berlin', country: 'Germany' },
  { city: 'Toronto', country: 'Canada' },
];

export function EventForm({ initialData, onSubmit }: EventFormProps) {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    startDate: initialData?.startDate || '',
    endDate: initialData?.endDate || '',
    location: initialData?.location || { city: '', country: '' },
    website: initialData?.website || '',
    imageUrl: initialData?.imageUrl || ''
  });

  const [customLocation, setCustomLocation] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate dates
    if (new Date(formData.startDate) > new Date(formData.endDate)) {
      toast({
        title: "Invalid dates",
        description: "End date must be after start date",
        variant: "destructive"
      });
      return;
    }

    onSubmit(formData);
    toast({
      title: initialData ? "Event updated" : "Event created",
      description: `Successfully ${initialData ? 'updated' : 'created'} event "${formData.title}"`,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-2">
        <label className="text-sm font-medium">Title</label>
        <Input
          value={formData.title}
          onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
          placeholder="Enter event title"
          required
        />
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Description</label>
        <Textarea
          value={formData.description}
          onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
          placeholder="Enter event description"
          required
          className="min-h-[100px]"
        />
      </div>

      <div className="grid gap-4 sm:grid-cols-2">
        <div className="space-y-2">
          <label className="text-sm font-medium">Start Date</label>
          <Input
            type="date"
            value={formData.startDate}
            onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
            required
          />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">End Date</label>
          <Input
            type="date"
            value={formData.endDate}
            onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Location</label>
        <div className="flex items-center gap-2">
          <Select
            value={`${formData.location.city},${formData.location.country}`}
            onValueChange={(value) => {
              if (value === 'custom') {
                setCustomLocation(true);
              } else {
                const [city, country] = value.split(',');
                setFormData(prev => ({
                  ...prev,
                  location: { city, country }
                }));
                setCustomLocation(false);
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select location" />
            </SelectTrigger>
            <SelectContent>
              {POPULAR_LOCATIONS.map(({ city, country }) => (
                <SelectItem key={`${city},${country}`} value={`${city},${country}`}>
                  {city}, {country}
                </SelectItem>
              ))}
              <SelectItem value="custom">Custom Location</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {customLocation && (
          <div className="mt-2 grid gap-2 sm:grid-cols-2">
            <Input
              placeholder="City"
              value={formData.location.city}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                location: { ...prev.location, city: e.target.value }
              }))}
            />
            <Input
              placeholder="Country"
              value={formData.location.country}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                location: { ...prev.location, country: e.target.value }
              }))}
            />
          </div>
        )}
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Website</label>
        <Input
          type="url"
          value={formData.website}
          onChange={(e) => setFormData(prev => ({ ...prev, website: e.target.value }))}
          placeholder="https://example.com"
          required
        />
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Image URL</label>
        <Input
          type="url"
          value={formData.imageUrl}
          onChange={(e) => setFormData(prev => ({ ...prev, imageUrl: e.target.value }))}
          placeholder="https://example.com/image.jpg"
        />
      </div>

      <Button type="submit" className="w-full">
        {initialData ? 'Update Event' : 'Create Event'}
      </Button>
    </form>
  );
}