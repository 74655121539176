import { useState, useEffect } from 'react';
import { Shield } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useSearchParams } from 'react-router-dom';
import { AdminLogin } from '@/components/admin/AdminLogin';
import { AdminContent } from '@/components/admin/AdminContent';

const ADMIN_PASSWORD = 'newsdotfun';

export default function Admin() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchParams] = useSearchParams();
  const editId = searchParams.get('edit');
  const [activeTab, setActiveTab] = useState('list');

  useEffect(() => {
    if (editId) {
      setActiveTab('add');
    }
  }, [editId]);

  if (!isAuthenticated) {
    return <AdminLogin onLogin={() => setIsAuthenticated(true)} />;
  }

  return (
    <div className="container mx-auto py-12">
      {/* Remove prediction banner for admin */}
      <style>{`
        .prediction-header {
          display: none !important;
        }
      `}</style>
      
      <Card className="p-6">
        <h2 className="mb-6 flex items-center gap-2 text-xl font-bold">
          <Shield className="h-5 w-5 text-primary" />
          Content Management
        </h2>

        <AdminContent activeTab={activeTab} onTabChange={setActiveTab} editId={editId} />
      </Card>
    </div>
  );
}