import { create } from 'zustand';
import { supabase } from '@/lib/supabase/client';
import { useAuthStore } from './auth-store';

interface Prediction {
  id: string;
  user_id: string;
  username: string;
  token: string;
  direction: 'up' | 'down';
  percentage: number;
  time_frame: string;
  wager: number;
  status: 'active' | 'won' | 'lost';
  created_at: string;
  completed_at?: string;
}

interface PredictionState {
  predictions: Prediction[];
  isLoading: boolean;
  error: string | null;
  loadPredictions: () => Promise<void>;
  createPrediction: (prediction: Omit<Prediction, 'id' | 'user_id' | 'created_at' | 'status' | 'completed_at'>) => Promise<void>;
  updatePrediction: (id: string, updates: Partial<Prediction>) => Promise<void>;
  getActivePredictions: () => Prediction[];
  getCompletedPredictions: () => Prediction[];
}

export const usePredictionStore = create<PredictionState>((set, get) => ({
  predictions: [],
  isLoading: false,
  error: null,

  loadPredictions: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data, error } = await supabase
        .from('user_predictions')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      set({ predictions: data || [] });
    } catch (error) {
      console.error('Error loading predictions:', error);
      set({ error: 'Failed to load predictions' });
    } finally {
      set({ isLoading: false });
    }
  },

  createPrediction: async (prediction) => {
    try {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData.user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('user_predictions')
        .insert([{
          ...prediction,
          user_id: userData.user.id,
          status: 'active',
          created_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;

      set(state => ({
        predictions: [data, ...state.predictions]
      }));
    } catch (error) {
      console.error('Error creating prediction:', error);
      set({ error: 'Failed to create prediction' });
      throw error;
    }
  },

  updatePrediction: async (id, updates) => {
    try {
      const { error } = await supabase
        .from('user_predictions')
        .update({
          ...updates,
          completed_at: updates.status !== 'active' ? new Date().toISOString() : null
        })
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        predictions: state.predictions.map(prediction =>
          prediction.id === id ? { ...prediction, ...updates } : prediction
        )
      }));
    } catch (error) {
      console.error('Error updating prediction:', error);
      set({ error: 'Failed to update prediction' });
    }
  },

  getActivePredictions: () => {
    return get().predictions.filter(p => p.status === 'active');
  },

  getCompletedPredictions: () => {
    return get().predictions.filter(p => p.status !== 'active');
  }
}));