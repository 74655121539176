import { MessageSquare } from 'lucide-react';

export function BlurtsHeader() {
  return (
    <div className="flex items-center gap-4">
      <MessageSquare className="h-10 w-10 text-primary" />
      <div>
        <h1 className="text-4xl font-bold">Blurts Timeline</h1>
        <p className="text-muted-foreground">Community discussions and predictions</p>
      </div>
    </div>
  );
}