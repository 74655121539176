import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useAuth } from '@/lib/auth';

interface BlurtAvatarProps {
  username?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const sizes = {
  sm: 'h-8 w-8',
  md: 'h-10 w-10',
  lg: 'h-12 w-12'
};

export function BlurtAvatar({ username = 'Anonymous', size = 'md', className }: BlurtAvatarProps) {
  const { user } = useAuth();
  const initials = username.slice(0, 2).toUpperCase();
  
  // Use the auth user's avatar if it matches the username
  const avatarUrl = user?.username === username ? user.avatar : undefined;

  return (
    <Avatar className={sizes[size]}>
      <AvatarImage src={avatarUrl} alt={username} />
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  );
}