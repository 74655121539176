import { OnboardingStep } from './types';

export const ONBOARDING_STEPS: OnboardingStep[] = [
  {
    target: '[data-tour="dashboard"]',
    title: 'Welcome to NextCoinNews',
    content: 'Your unified crypto intelligence platform that brings together news, social media, and market data in one intuitive dashboard.',
    placement: 'center'
  },
  {
    target: '[data-tour="market-analysis"]',
    title: 'Market Analysis Tools',
    content: 'Track prices, analyze trends, and make informed decisions with our comprehensive market analysis tools.',
    placement: 'left'
  },
  {
    target: '[data-tour="news-feed"]',
    title: 'Real-time News Feed',
    content: 'Stay informed with aggregated news from trusted sources, social media updates from X, TikTok, and YouTube.',
    placement: 'right'
  },
  {
    target: '[data-tour="community"]',
    title: 'Community Features',
    content: 'Engage with the crypto community through comments, favorites, and shared insights.',
    placement: 'bottom'
  }
];