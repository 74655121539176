import { create } from 'zustand';
import { supabase } from '@/lib/supabase/client';
import { useNotificationStore } from './notification-store';
import { useAuthStore } from './auth-store';

interface Comment {
  id: string;
  articleId: string;
  username: string;
  content: string;
  timestamp: Date;
}

interface CommentState {
  comments: Comment[];
  isLoading: boolean;
  error: string | null;
  addComment: (articleId: string, content: string) => Promise<void>;
  getComments: (articleId: string) => Comment[];
  loadComments: () => Promise<void>;
}

export const useCommentStore = create<CommentState>((set, get) => ({
  comments: [],
  isLoading: false,
  error: null,

  loadComments: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data, error } = await supabase
        .from('user_interactions')
        .select('*')
        .eq('interaction_type', 'comment')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const comments = data.map(row => ({
        id: row.id,
        articleId: row.content_id,
        username: row.user_id, // We'll need to fetch usernames separately
        content: row.content,
        timestamp: new Date(row.created_at)
      }));

      set({ comments, isLoading: false });
    } catch (error) {
      console.error('Error loading comments:', error);
      set({ error: 'Failed to load comments', isLoading: false });
    }
  },

  addComment: async (articleId: string, content: string) => {
    try {
      const { user } = useAuthStore.getState();
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('user_interactions')
        .insert([{
          user_id: user.id,
          content_id: articleId,
          interaction_type: 'comment',
          content: content
        }])
        .select()
        .single();

      if (error) throw error;

      const newComment = {
        id: data.id,
        articleId: data.content_id,
        username: user.username || 'Anonymous',
        content: data.content,
        timestamp: new Date(data.created_at)
      };

      set(state => ({
        comments: [newComment, ...state.comments]
      }));

      useNotificationStore.getState().addNotification({
        title: 'New Comment',
        description: `${newComment.username} commented: ${content.slice(0, 50)}${content.length > 50 ? '...' : ''}`
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      throw error;
    }
  },

  getComments: (articleId: string) => {
    return get().comments.filter(comment => comment.articleId === articleId);
  }
}));