import { XMLParser } from 'fast-xml-parser';

const parser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: "@_",
  allowBooleanAttributes: true,
});

export async function fetchFeed(url: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      console.warn(`Failed to fetch feed: ${url}`);
      return [];
    }
    
    const text = await response.text();
    let data;

    // Try parsing as JSON first
    try {
      data = JSON.parse(text);
      // Handle RSS.app JSON format
      if (data.status === 'ok' && Array.isArray(data.items)) {
        return data.items.map(processRSSItem);
      }
      // Handle other JSON feed formats
      if (Array.isArray(data)) {
        return data.map(processRSSItem);
      }
      // Handle single item JSON format
      if (data.title && data.link) {
        return [processRSSItem(data)];
      }
      return [];
    } catch {
      // If JSON parsing fails, try XML
      try {
        data = parser.parse(text);
        const channel = data.rss?.channel;
        if (!channel?.item) {
          return [];
        }
        const items = Array.isArray(channel.item) ? channel.item : [channel.item];
        return items.map(processRSSItem).filter(Boolean);
      } catch (xmlError) {
        console.warn(`Failed to parse feed ${url}:`, xmlError);
        return [];
      }
    }
  } catch (error) {
    console.warn('Feed error:', url, error);
    return [];
  }
}

export function processRSSItem(item: any) {
  if (!item) return null;

  // Extract image from various possible locations
  const image = item.enclosure?.["@_url"] ||
    item["media:content"]?.["@_url"] ||
    item["media:thumbnail"]?.["@_url"] ||
    (typeof item.image === 'string' ? item.image : item.image?.url) ||
    (item.description?.match(/<img[^>]+src="([^">]+)"/) || [])[1];

  // Clean up description text
  const description = item.description?.replace(/<[^>]*>/g, '') || 
    item.summary?.replace(/<[^>]*>/g, '') || 
    '';

  // Generate a unique ID
  const id = item.guid || 
    item.link || 
    `${item.title}-${new Date(item.pubDate).getTime()}`;

  return {
    id,
    title: item.title || 'Untitled',
    link: item.link || item.guid,
    pubDate: item.pubDate || item.published || new Date().toISOString(),
    description,
    image,
    source: item.source?.["#text"] || new URL(item.link).hostname,
    creator: item["dc:creator"] || item.author || 'Unknown'
  };
}