export const initialEvents = [
  {
    id: '1',
    title: "WAGMI Miami",
    description: "The ultimate Web3 conference in Miami, bringing together builders, investors, and enthusiasts.",
    startDate: "2025-01-22",
    endDate: "2025-01-24",
    location: {
      city: "Miami",
      country: "United States"
    },
    website: "https://wagmi.miami",
    imageUrl: "https://i.postimg.cc/YqHLhkfS/Screenshot-2025-01-06-at-11-48-32-PM.png"
  },
  {
    id: '2',
    title: "Token2049 Dubai",
    description: "Premier crypto event in Dubai featuring industry leaders, investors, and innovators discussing the future of Web3.",
    startDate: "2025-04-30",
    endDate: "2025-05-01",
    location: {
      city: "Dubai",
      country: "UAE"
    },
    website: "https://www.dubai.token2049.com/",
    imageUrl: "https://i.postimg.cc/QtCHZd36/Screenshot-2025-01-06-at-11-47-48-PM.png"
  },
  {
    id: '3',
    title: "Paris Blockchain Week",
    description: "Europe's largest blockchain gathering, bringing together entrepreneurs, investors, and developers.",
    startDate: "2024-04-09",
    endDate: "2024-04-11",
    location: {
      city: "Paris",
      country: "France"
    },
    website: "https://www.parisblockchainweek.com/",
    imageUrl: "https://i.postimg.cc/3NbjQcVG/Screenshot-2025-01-06-at-11-46-12-PM.png"
  }
];