import { useState } from 'react';
import { Eye, EyeOff, Filter } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ArticleCard } from './ArticleCard';
import { SectionHeader } from './section/SectionHeader';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { generateUniqueId } from '@/lib/utils/generateId';
import { FEED_KEYWORDS } from '@/lib/constants/feeds';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface FeedSectionProps {
  title: string;
  articles: any[];
  feedKey: string;
  priority?: boolean;
}

export function FeedSection({ title, articles, feedKey, priority = false }: FeedSectionProps) {
  const [showHidden, setShowHidden] = useState(false);
  const [hiddenArticles, setHiddenArticles] = useState<Set<string>>(new Set());
  const [selectedSource, setSelectedSource] = useState<string | null>(null);

  // Get unique sources from articles
  const sources = Array.from(new Set(articles.map(article => {
    try {
      return new URL(article.link).hostname.replace('www.', '');
    } catch {
      return 'unknown';
    }
  }))).sort();

  const visibleArticles = articles?.filter(article => {
    const isVisible = showHidden || !hiddenArticles.has(article.link || article.url);
    if (!isVisible) return false;
    
    if (selectedSource) {
      try {
        const hostname = new URL(article.link).hostname.replace('www.', '');
        return hostname === selectedSource;
      } catch {
        return false;
      }
    }
    
    return true;
  });

  const handleHideArticle = (articleId: string) => {
    setHiddenArticles(prev => {
      const newSet = new Set(prev);
      if (prev.has(articleId)) {
        newSet.delete(articleId);
      } else {
        newSet.add(articleId);
      }
      return newSet;
    });
  };

  if (!articles?.length) return null;

  return (
    <section className="space-y-4">
      <SectionHeader title={title} keywords={FEED_KEYWORDS[feedKey as keyof typeof FEED_KEYWORDS]}>
        <div className="flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="h-8 w-8"
              >
                <Filter className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem 
                onClick={() => setSelectedSource(null)}
                className={cn(selectedSource === null && "bg-muted")}
              >
                All Sources
              </DropdownMenuItem>
              {sources.map(source => (
                <DropdownMenuItem
                  key={source}
                  onClick={() => setSelectedSource(source)}
                  className={cn(selectedSource === source && "bg-muted")}
                >
                  {source}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="outline"
            size="icon"
            onClick={() => setShowHidden(!showHidden)}
            className="h-8 w-8"
          >
            {showHidden ? (
              <EyeOff className="h-4 w-4" />
            ) : (
              <Eye className="h-4 w-4" />
            )}
          </Button>
        </div>
      </SectionHeader>

      <ScrollArea orientation="horizontal" className="w-full">
        <div className="flex gap-6 pb-4">
          {visibleArticles.map((article) => {
            const articleId = generateUniqueId(`${feedKey}-article-`);
            return (
              <div 
                key={articleId}
                className="w-[300px] flex-none sm:w-[320px] md:w-[340px]"
                style={{
                  opacity: hiddenArticles.has(article.link || article.url) ? 0.5 : 1,
                }}
              >
                <ArticleCard 
                  article={article} 
                  isHidden={hiddenArticles.has(article.link || article.url)}
                  onHide={() => handleHideArticle(article.link || article.url)}
                />
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </section>
  );
}