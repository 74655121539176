import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-9PKC4DDF5S');

    // Add script tags
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-9PKC4DDF5S';

    document.head.appendChild(script1);
  }, []);

  // Track page views
  useEffect(() => {
    window.gtag('config', 'G-9PKC4DDF5S', {
      page_path: location.pathname + location.search + location.hash,
    });
  }, [location]);

  return null;
}