import { useEffect } from 'react';

export function CoinGeckoStaticHeadline() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/gecko-coin-price-static-headline-widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full">
      <gecko-coin-price-static-headline-widget 
        locale="en" 
        dark-mode="true" 
        transparent-background="true" 
        coin-ids="bitcoin,ethereum,binancecoin,solana,sui,cardano,ripple,magic-eden,pudgy-penguins,bonk,jupiter-exchange-solana,tensor,the-open-network,dogecoin,mog-coin,pepe,popcat,peanut-the-squirrel,moo-deng,mother-iggy" 
        initial-currency="usd"
      />
    </div>
  );
}