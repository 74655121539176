import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/lib/auth';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useToast } from '@/hooks/use-toast';
import { Settings as SettingsIcon } from 'lucide-react';

export default function Settings() {
  const { user, updateUser } = useAuth();
  const [formData, setFormData] = useState({
    username: user?.username || '',
    email: user?.email || '',
  });
  const { toast } = useToast();

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newAvatar = e.target?.result as string;
          // Update both local state and auth store
          updateUser({ 
            ...user,
            avatar: newAvatar 
          });
          toast({
            title: "Success",
            description: "Profile picture updated successfully",
          });
        };
        reader.readAsDataURL(file);
      }
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleProfileUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    updateUser({
      ...user,
      username: formData.username,
      email: formData.email,
    });
    toast({
      title: "Success",
      description: "Profile updated successfully",
    });
  };

  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <SettingsIcon className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Settings</h1>
          <p className="text-muted-foreground">Manage your account settings</p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Profile</CardTitle>
          <CardDescription>
            Manage your profile information
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <form onSubmit={handleProfileUpdate} className="space-y-6">
            <div className="flex flex-col items-center space-y-4">
              <div {...getRootProps()} className="relative">
                <input {...getInputProps()} />
                <Avatar className="h-24 w-24 cursor-pointer" onClick={open}>
                  <AvatarImage src={user?.avatar} />
                  <AvatarFallback>
                    {user?.username?.slice(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <Button
                  variant="secondary"
                  size="sm"
                  className="absolute -bottom-2 left-1/2 -translate-x-1/2"
                  onClick={open}
                  type="button"
                >
                  Change
                </Button>
              </div>
              <div className="w-full space-y-2">
                <Label htmlFor="username">Username</Label>
                <Input 
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input 
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <Button 
                type="submit"
                className="w-full bg-gradient-to-r from-[#FFAA00] to-[#CA5003] text-white hover:from-[#FFAA00]/90 hover:to-[#CA5003]/90"
              >
                Save Changes
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}