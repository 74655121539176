import { useEffect, useRef } from 'react';
import { Card } from '@/components/ui/card';

interface DarqubeScreenerProps {
  height?: number;
}

export function DarqubeScreener({ height = 800 }: DarqubeScreenerProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const widgetId = `ScreenerWidget-${Math.random().toString(36).slice(2)}`;

  useEffect(() => {
    const handleMessage = (msg: MessageEvent) => {
      const iframe = iframeRef.current;
      if (!iframe) return;

      const styles = msg.data?.styles;
      const token = msg.data?.token;
      const urlToken = new URL(iframe.src)?.searchParams?.get?.('token');
      
      if (styles && token === urlToken) {
        Object.keys(styles).forEach(key => iframe.style.setProperty(key, styles[key]));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <Card className="overflow-hidden rounded-2xl bg-card/50 backdrop-blur-sm">
      <div className="overflow-hidden rounded-2xl bg-background/50">
        <iframe
          ref={iframeRef}
          id={widgetId}
          src="https://widget.darqube.com/screener-widget?token=676a222c886e7cf27b55baa0"
          style={{ 
            border: 'none', 
            width: '100%', 
            height: `${height}px`,
            backgroundColor: 'transparent',
            borderRadius: '16px'
          }}
        />
      </div>
    </Card>
  );
}