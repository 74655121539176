import { createContext, useContext, useState, useEffect } from 'react';
import { OnboardingOverlay } from './OnboardingOverlay';
import { OnboardingStep } from './types';
import { ONBOARDING_STEPS } from './steps';

interface OnboardingContextType {
  currentStep: number;
  nextStep: () => void;
  previousStep: () => void;
  skipTutorial: () => void;
  isActive: boolean;
  startTutorial: () => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export function OnboardingProvider({ children }: { children: React.ReactNode }) {
  const [currentStep, setCurrentStep] = useState(-1);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const hasSeenTutorial = localStorage.getItem('hasSeenTutorial');
    if (!hasSeenTutorial) {
      setIsActive(true);
      setCurrentStep(0);
    }
  }, []);

  const nextStep = () => {
    if (currentStep < ONBOARDING_STEPS.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      skipTutorial();
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const skipTutorial = () => {
    setIsActive(false);
    setCurrentStep(-1);
    localStorage.setItem('hasSeenTutorial', 'true');
  };

  const startTutorial = () => {
    setIsActive(true);
    setCurrentStep(0);
  };

  return (
    <OnboardingContext.Provider 
      value={{ 
        currentStep, 
        nextStep, 
        previousStep, 
        skipTutorial,
        isActive,
        startTutorial
      }}
    >
      {children}
      {isActive && <OnboardingOverlay step={ONBOARDING_STEPS[currentStep]} />}
    </OnboardingContext.Provider>
  );
}

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};