import { MessageSquare, TrendingUp, ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface BlurtsFilterProps {
  filter: 'all' | 'comments' | 'predictions' | 'active' | 'completed';
  sort: 'newest' | 'winning' | 'losing';
  onFilterChange: (filter: 'all' | 'comments' | 'predictions' | 'active' | 'completed') => void;
  onSortChange: (sort: 'newest' | 'winning' | 'losing') => void;
}

export function BlurtsFilter({ filter, sort, onFilterChange, onSortChange }: BlurtsFilterProps) {
  return (
    <div className="flex flex-wrap items-center justify-between gap-4">
      <div className="flex flex-wrap gap-2">
        <Button
          variant={filter === 'all' ? 'default' : 'outline'}
          onClick={() => onFilterChange('all')}
        >
          All
        </Button>
        <Button
          variant={filter === 'comments' ? 'default' : 'outline'}
          onClick={() => onFilterChange('comments')}
        >
          <MessageSquare className="mr-2 h-4 w-4" />
          Comments
        </Button>
        <Button
          variant={filter === 'predictions' ? 'default' : 'outline'}
          onClick={() => onFilterChange('predictions')}
        >
          <TrendingUp className="mr-2 h-4 w-4" />
          All Predictions
        </Button>
        <Button
          variant={filter === 'active' ? 'default' : 'outline'}
          onClick={() => onFilterChange('active')}
        >
          Active Predictions
        </Button>
        <Button
          variant={filter === 'completed' ? 'default' : 'outline'}
          onClick={() => onFilterChange('completed')}
        >
          Completed Predictions
        </Button>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            <ArrowUpDown className="mr-2 h-4 w-4" />
            {sort === 'newest' ? 'Newest First' : 
             sort === 'winning' ? 'Winning' : 'Losing'}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => onSortChange('newest')}>
            Newest First
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSortChange('winning')}>
            Winning
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSortChange('losing')}>
            Losing
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}