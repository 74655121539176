import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { CoinGeckoTicker } from './tickers/CoinGeckoTicker';
import { cn } from '@/lib/utils';
import { useOnboarding } from './onboarding/OnboardingProvider';

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isActive } = useOnboarding();

  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [sidebarOpen]);

  return (
    <div className={cn(
      "relative min-h-screen bg-background",
      isActive && "overflow-hidden"
    )}>
      <div className="gradient-bg-1" />
      <div className="gradient-bg-2" />

      {/* Fixed Header */}
      <div className="fixed left-0 right-0 top-0 z-40">
        <CoinGeckoTicker />
        <Header onMenuClick={() => setSidebarOpen(true)} />
      </div>

      {/* Mobile Sidebar Overlay */}
      <div
        className={cn(
          "fixed inset-0 z-50 bg-black/80 transition-opacity lg:hidden",
          sidebarOpen ? "opacity-100" : "pointer-events-none opacity-0"
        )}
        onClick={() => setSidebarOpen(false)}
      />

      <div className="flex">
        {/* Fixed Sidebar */}
        <div
          className={cn(
            "fixed inset-y-0 left-0 z-50 w-64 transform bg-black transition-transform duration-200 ease-in-out lg:translate-x-0",
            sidebarOpen ? "translate-x-0" : "-translate-x-full"
          )}
        >
          <Sidebar onClose={() => setSidebarOpen(false)} />
        </div>

        {/* Main Content Area */}
        <div className="flex min-h-screen w-full flex-col lg:pl-64">
          <main className="container mx-auto flex-1 overflow-hidden p-4 pt-32 md:p-6 md:pt-32">
            <div className="mx-auto max-w-[1400px]">
              <Outlet />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}