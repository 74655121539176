import { useState, useCallback } from 'react';

interface ArticleAnalysis {
  story_title: string;
  key_points: string[];
  tickers: string[];
}

export function useArticleAnalysis() {
  const [analysis, setAnalysis] = useState<ArticleAnalysis | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const analyze = useCallback(async (url: string) => {
    setLoading(true);
    setError(null);
    setAnalysis(null);
    
    try {
      // Use the correct endpoint
      const response = await fetch(`https://api.roomfourtyfour.com/fetchArticle?url=${encodeURIComponent(url)}`);

      if (!response.ok) {
        throw new Error('Analysis failed');
      }

      const data = await response.json();
      
      if (!data.story_title || !Array.isArray(data.key_points)) {
        throw new Error('Invalid analysis data structure');
      }

      setAnalysis({
        story_title: data.story_title,
        key_points: data.key_points || [],
        tickers: data.tickers || []
      });
    } catch (err) {
      console.error('Analysis error:', err);
      setError(err instanceof Error ? err.message : 'Analysis failed');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    analysis,
    loading,
    error,
    analyze,
  };
}