import { useState } from 'react';
import { Eye, EyeOff, ThumbsUp, ThumbsDown } from 'lucide-react';
// ... other imports

export default function FeedPage() {
  const [sortByVotes, setSortByVotes] = useState<'up' | 'down' | null>(null);
  // ... existing state

  const sortedArticles = useMemo(() => {
    if (!sortByVotes) return visibleArticles;
    
    return [...visibleArticles].sort((a, b) => {
      const aVotes = (a.votes?.[sortByVotes] || 0);
      const bVotes = (b.votes?.[sortByVotes] || 0);
      return sortByVotes === 'up' ? bVotes - aVotes : aVotes - bVotes;
    });
  }, [visibleArticles, sortByVotes]);

  return (
    <div className="space-y-8 pt-8">
      {/* ... existing header */}

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowHidden(!showHidden)}
          >
            {showHidden ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setSortByVotes(sortByVotes === 'up' ? 'down' : 'up')}
          >
            {sortByVotes === 'down' ? (
              <ThumbsDown className="h-4 w-4" />
            ) : (
              <ThumbsUp className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {sortedArticles.map((article) => (
          <ArticleCard
            key={article.url || article.link}
            article={article}
            isHidden={hiddenArticles.has(article.link || article.url)}
            onHide={() => handleHideArticle(article.link || article.url)}
          />
        ))}
      </div>
    </div>
  );
}