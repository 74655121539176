import { useState } from 'react';
import { Menu, Star, MessageSquare } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { Logo } from './Logo';
import { SearchModal } from './SearchModal';
import { SummaryModal } from './summary/SummaryModal';
import { FeedControls } from './header/FeedControls';
import { ProfileMenu } from './header/ProfileMenu';
import { useFeedStore } from '@/lib/store';

interface HeaderProps {
  className?: string;
  onMenuClick: () => void;
}

export default function Header({ className, onMenuClick }: HeaderProps) {
  const [showSearch, setShowSearch] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const { feeds } = useFeedStore();
  const navigate = useNavigate();

  return (
    <header className={`border-b border-border bg-background/80 px-4 backdrop-blur-sm ${className}`}>
      <div className="flex h-16 items-center justify-between">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="lg:hidden"
            onClick={onMenuClick}
          >
            <Menu className="h-5 w-5" />
          </Button>
          <Link to="/">
            <Logo className="lg:hidden" />
          </Link>
        </div>

        <FeedControls onSearchClick={() => setShowSearch(true)} />
        
        <div className="flex items-center gap-2">
          <div className="hidden sm:flex sm:items-center sm:gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={() => navigate('/favorites')}
            >
              <Star className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={() => navigate('/blurts')}
            >
              <MessageSquare className="h-4 w-4" />
            </Button>
          </div>
          <ProfileMenu />
        </div>
      </div>

      <SearchModal 
        open={showSearch}
        onClose={() => setShowSearch(false)}
      />

      <SummaryModal 
        open={showSummary}
        onClose={() => setShowSummary(false)}
        stories={[]}
      />
    </header>
  );
}