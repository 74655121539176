import { Coins } from 'lucide-react';
import { DarqubeScreener } from '@/components/tokens/DarqubeScreener';
import { DarqubeAdvancedChart } from '@/components/charts/DarqubeAdvancedChart';
import { DarqubeNewsWidget } from '@/components/widgets/DarqubeNewsWidget';

export default function Tokens() {
  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <Coins className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Tokens</h1>
          <p className="text-muted-foreground">Track and analyze token performance</p>
        </div>
      </div>

      <div className="grid gap-8 lg:grid-cols-[1fr_330px]">
        <div className="space-y-8">
          <DarqubeAdvancedChart />
          <DarqubeScreener height={800} />
        </div>
        
        <div className="lg:sticky lg:top-32 lg:h-[calc(100vh-8rem)]">
          <DarqubeNewsWidget />
        </div>
      </div>
    </div>
  );
}