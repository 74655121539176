import { XMLParser } from 'fast-xml-parser';

const SUPPORTED_TOKENS = ['BTC', 'ETH', 'SOL'];

export async function getCurrentPrice(symbol: string): Promise<number | null> {
  try {
    const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${getTokenId(symbol)}&vs_currencies=usd`);
    const data = await response.json();
    return data[getTokenId(symbol)].usd;
  } catch (error) {
    console.error('Error fetching price:', error);
    return null;
  }
}

function getTokenId(symbol: string): string {
  const mapping: Record<string, string> = {
    'BTC': 'bitcoin',
    'ETH': 'ethereum',
    'SOL': 'solana'
  };
  return mapping[symbol] || symbol.toLowerCase();
}

export function calculatePredictionOutcome(
  startPrice: number,
  endPrice: number,
  direction: 'up' | 'down',
  targetPercentage: number
): 'won' | 'lost' {
  const actualPercentageChange = ((endPrice - startPrice) / startPrice) * 100;
  
  if (direction === 'up') {
    return actualPercentageChange >= targetPercentage ? 'won' : 'lost';
  } else {
    return actualPercentageChange <= -targetPercentage ? 'won' : 'lost';
  }
}

export function getTimeFrameInMs(timeFrame: string): number {
  const value = parseInt(timeFrame);
  if (timeFrame.endsWith('m')) return value * 60 * 1000;
  if (timeFrame.endsWith('h')) return value * 60 * 60 * 1000;
  return 0;
}