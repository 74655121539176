import { useEffect } from 'react';
import { useFeedStore } from '@/lib/store';
import { useAuth } from '@/lib/auth';
import { FeedSection } from '@/components/FeedSection';
import { RecentEventsSection } from '@/components/events/RecentEventsSection';
import { NextPerspective } from '@/components/blog/NextPerspective';
import { useDashboardFeeds } from '@/hooks/use-dashboard-feeds';
import { CoinGeckoStaticHeadline } from '@/components/tickers/CoinGeckoStaticHeadline';

export default function Dashboard() {
  const { feeds, fetchFeeds } = useFeedStore();
  const { user } = useAuth();
  const { feedSections } = useDashboardFeeds();

  useEffect(() => {
    fetchFeeds();
  }, [fetchFeeds]);

  return (
    <div className="space-y-8 pt-8">
      <div>
        <h1 className="text-4xl font-bold">
          Next-Ray Vision
        </h1>
        <p className="text-muted-foreground">
          Spot trending stories and scan Solana for AI suggested tickers
        </p>
      </div>

      <CoinGeckoStaticHeadline />

      <div className="space-y-8">
        {/* NextCoinNews Section */}
        <FeedSection
          feedKey="nextcoinnews"
          title="NextCoinNews"
          articles={feeds.nextcoinnews || []}
          priority={true}
        />

        <RecentEventsSection />

        {feedSections.map(({ key, title, articles, priority }) => (
          <FeedSection
            key={key}
            feedKey={key}
            title={title}
            articles={articles}
            priority={priority}
          />
        ))}

        <NextPerspective />
      </div>
    </div>
  );
}