import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { ArticleInteractions } from '../ArticleInteractions';
import { ArticleActions } from '../article/ArticleActions';
import { useArticleAnalysis } from '@/hooks/use-article-analysis';
import { ArticleAnalysisModal } from '../article/ArticleAnalysisModal';
import { useState } from 'react';

interface BlogCardProps {
  post: {
    id: string;
    url: string;
    title: string;
    excerpt: string;
    author: string;
    date: string;
    image?: string;
    content: string; // Add this to ensure we have content to analyze
  };
  className?: string;
}

export function BlogCard({ post, className }: BlogCardProps) {
  const { analyze, analysis, loading, error } = useArticleAnalysis();
  const [showAnalysis, setShowAnalysis] = useState(false);

  const handleAnalyze = () => {
    // Create a temporary HTML element with the blog content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = post.content;
    
    // Extract text content and create a simple HTML structure
    const textContent = tempDiv.textContent || tempDiv.innerText;
    const htmlContent = `
      <html>
        <head><title>${post.title}</title></head>
        <body>
          <h1>${post.title}</h1>
          <p>${textContent}</p>
        </body>
      </html>
    `;

    // Create a blob URL from the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    // Analyze the content and show the modal
    analyze(url);
    setShowAnalysis(true);

    // Clean up the blob URL after analysis
    setTimeout(() => URL.revokeObjectURL(url), 1000);
  };

  return (
    <>
      <Card className={cn("h-full overflow-hidden transition-shadow hover:shadow-lg", className)}>
        {post.image && (
          <div className="aspect-video w-full overflow-hidden">
            <img
              src={post.image}
              alt={post.title}
              className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
            />
          </div>
        )}
        <div className="flex flex-col p-6">
          <Link to={post.url} className="group">
            <h2 className="mb-2 text-xl font-semibold group-hover:text-primary">
              {post.title}
            </h2>
            <p className="mb-4 text-sm text-muted-foreground line-clamp-2">
              {post.excerpt}
            </p>
            <div className="flex items-center justify-between text-sm text-muted-foreground">
              <span>{post.author}</span>
              <span>{format(new Date(post.date), 'MMM d, yyyy')}</span>
            </div>
          </Link>

          <div className="mt-4 border-t border-border pt-4">
            <ArticleActions 
              articleId={post.url} 
              onAnalyze={handleAnalyze}
            />
            <ArticleInteractions articleId={post.url} />
          </div>
        </div>
      </Card>

      <ArticleAnalysisModal
        open={showAnalysis}
        onClose={() => setShowAnalysis(false)}
        analysis={analysis}
        loading={loading}
        error={error}
      />
    </>
  );
}