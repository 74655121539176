export interface LeaderboardEntry {
  id: string;
  username: string;
  avatar?: string;
  gems: number;
  rank: number;
  winRate: number;
  totalTrades: number;
  totalPnl: number;
  weeklyPnl: number;
  bestTrade: {
    token: string;
    profit: number;
    date: string;
  };
}

export const leaderboardData: LeaderboardEntry[] = [
  // ... existing leaderboard data ...
];