import { useEffect } from 'react';
import { usePredictionStore } from '@/lib/store';
import { useTokenStore } from '@/lib/store';
import { getCurrentPrice, calculatePredictionOutcome, getTimeFrameInMs } from '@/lib/utils/price-checker';
import { calculatePayout } from '@/lib/constants/predictions';

export function usePredictionChecker() {
  const { predictions, updatePrediction } = usePredictionStore();
  const { updateGems } = useTokenStore();

  useEffect(() => {
    const checkPredictions = async () => {
      const activePredictions = predictions.filter(p => p.status === 'active');
      
      for (const prediction of activePredictions) {
        const endTime = new Date(prediction.timestamp);
        endTime.setTime(endTime.getTime() + getTimeFrameInMs(prediction.timeFrame));

        // Check if prediction period has ended
        if (new Date() >= endTime) {
          const currentPrice = await getCurrentPrice(prediction.token);
          const startPrice = await getCurrentPrice(prediction.token); // In production, store initial price
          
          if (currentPrice && startPrice) {
            const outcome = calculatePredictionOutcome(
              startPrice,
              currentPrice,
              prediction.direction,
              prediction.percentage
            );
            
            updatePrediction(prediction.id, { status: outcome });

            // If won, calculate and award payout
            if (outcome === 'won') {
              const payout = calculatePayout(prediction.wager, prediction.percentage);
              updateGems(payout);
            }
          }
        }
      }
    };

    const interval = setInterval(checkPredictions, 10000); // Check every 10 seconds
    checkPredictions(); // Initial check

    return () => clearInterval(interval);
  }, [predictions, updatePrediction, updateGems]);
}