import { useRef } from 'react';
import { TokenCard } from './tokens/TokenCard';
import { tokens } from '@/lib/tokens';
import { useLocation } from 'react-router-dom';

interface TokenBannerProps {
  minimal?: boolean;
}

export function TokenBanner({ minimal = false }: TokenBannerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // Hide Market Overview on specific pages
  const shouldHide = [
    '/notifications', 
    '/blurts', 
    '/favorites',
    '/predictions',
    '/pump-vision',
    '/team',
    '/settings',
    '/disclaimer',
    '/terms',
    '/privacy',
    '/nft-art',
    '/x-feed',
    '/search',
    '/news',
    '/market',
    '/tiktok',
    '/youtube',
    '/instagram'
  ].includes(location.pathname);

  if (shouldHide) return null;

  return (
    <div className="w-full border-b border-border bg-background/80 backdrop-blur-sm">
      <div className="w-full px-4">
        <div 
          ref={containerRef}
          className="no-scrollbar flex gap-4 overflow-x-auto pb-6"
          style={{
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}
        >
          {tokens.map((token, index) => (
            <TokenCard key={token.symbol} token={token} index={index} minimal={minimal} />
          ))}
        </div>
      </div>
    </div>
  );
}