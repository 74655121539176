export function DiamondIcon({ className }: { className?: string }) {
  return (
    <svg 
      className={className} 
      viewBox="0 0 59.333 59.333" 
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M59.169 16.79c.018-.041.028-.086.043-.129a1.31 1.31 0 0 0 .092-.417c0-.016.004-.029.004-.045 0-.035-.009-.07-.011-.105a1.562 1.562 0 0 0-.023-.229 1.57 1.57 0 0 0-.037-.164 1.195 1.195 0 0 0-.062-.197c-.02-.055-.042-.107-.067-.158a1.383 1.383 0 0 0-.097-.18c-.03-.049-.06-.098-.094-.143a1.283 1.283 0 0 0-.129-.156 1.211 1.211 0 0 0-.125-.129c-.02-.018-.035-.039-.055-.057l-15.167-13a1.666 1.666 0 0 0-1.302-.482H16a1.666 1.666 0 0 0-1.361.535l-14 13c-.01.008-.017.02-.026.029a1.28 1.28 0 0 0-.107.137c-.048.055-.097.109-.138.168-.03.041-.054.086-.08.131a1.546 1.546 0 0 0-.104.189c-.022.049-.04.098-.058.148a1.513 1.513 0 0 0-.063.205c-.013.051-.022.102-.031.154-.012.078-.02.154-.023.232-.001.031-.009.059-.009.09 0 .02.005.037.006.057.002.082.012.162.024.244.007.045.011.092.021.137.017.078.044.154.071.229.015.043.026.088.044.131.032.072.074.141.115.211.023.039.041.08.066.117l.003.006 27.666 40.335c.008.012.019.02.027.029.028.039.063.07.094.107a1.7 1.7 0 0 0 .3.297c.049.037.1.07.153.105.111.07.227.129.35.178.055.021.107.045.164.063.182.053.369.09.563.09h.008c.008 0 .015-.004.023-.004.185-.004.363-.037.536-.088.058-.018.111-.041.167-.064.123-.049.238-.107.348-.18.052-.033.104-.066.152-.105.114-.09.214-.193.307-.307.028-.033.061-.063.086-.098l.021-.023L58.982 17.13l.003-.006c.031-.045.054-.096.081-.145a1.31 1.31 0 0 0 .103-.189zM39.962 18l-10.32 31.91L19.05 18h20.912zm-17.3-4l6.986-6.986L36.454 14H22.662zM14.835 18l8.474 25.531L5.796 18h9.039zm29.33 0h9.37L35.806 43.845 44.165 18zm7.762-4h-9.888l-8.768-9h8.155L51.927 14zM16.784 5h9.221l-9 9H7.092L16.784 5z"/>
    </svg>
  );
}