import { Newspaper } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { SectionHeader } from '@/components/section/SectionHeader';
import { BlogCard } from './BlogCard';
import { useBlogStore } from '@/lib/store/blog-store';

export function NextPerspective() {
  const { posts } = useBlogStore();

  if (posts.length === 0) {
    return null;
  }

  return (
    <section className="space-y-4">
      <SectionHeader 
        title="Next Perspective" 
        keywords={['Our Blog']}
      >
        <div className="flex items-center gap-2">
          <Newspaper className="h-5 w-5 text-primary" />
        </div>
      </SectionHeader>

      <ScrollArea className="w-full pb-4">
        <div className="flex gap-6">
          {posts.map((post) => (
            <div key={post.id} className="w-[400px] flex-none">
              <BlogCard post={post} />
            </div>
          ))}
        </div>
      </ScrollArea>
    </section>
  );
}