import { create } from 'zustand';
import { supabase } from '@/lib/supabase/client';
import type { NotableEvent } from '@/lib/types/event';
import { initialEvents } from '@/lib/data/events';

interface EventsState {
  events: NotableEvent[];
  isLoading: boolean;
  error: string | null;
  loadEvents: () => Promise<void>;
  addEvent: (event: Omit<NotableEvent, 'id'>) => Promise<void>;
  removeEvent: (id: string) => Promise<void>;
  updateEvent: (id: string, event: Partial<NotableEvent>) => Promise<void>;
}

export const useEventsStore = create<EventsState>((set) => ({
  events: initialEvents, // Initialize with initial events
  isLoading: false,
  error: null,

  loadEvents: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('start_date', { ascending: true });

      if (error) throw error;

      // If no data in Supabase yet, use initial events
      set({ events: data?.length ? data : initialEvents, isLoading: false });
    } catch (error) {
      console.error('Error loading events:', error);
      // Fallback to initial events on error
      set({ events: initialEvents, error: 'Failed to load events', isLoading: false });
    }
  },

  // Rest of the store implementation remains the same...
}));