import { useEffect } from 'react';

export function CoinGeckoTicker() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/gecko-coin-price-marquee-widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full bg-black">
      <gecko-coin-price-marquee-widget 
        locale="en" 
        dark-mode="true" 
        transparent-background="true" 
        outlined="true" 
        coin-ids="bitcoin,ethereum,solana,ripple,cardano" 
        initial-currency="usd"
      />
    </div>
  );
}