import { useEffect } from 'react';
import { CommentBlurt } from './CommentBlurt';
import { PredictionBlurt } from './PredictionBlurt';
import { useCommentStore } from '@/lib/store';
import { usePredictionStore } from '@/lib/store/prediction-store';

interface TimelineItem {
  type: 'comment' | 'prediction';
  data: any;
  timestamp: Date;
  article?: any;
}

interface BlurtsTimelineProps {
  items: TimelineItem[];
}

export function BlurtsTimeline({ items }: BlurtsTimelineProps) {
  const { loadPredictions } = usePredictionStore();

  // Load predictions when component mounts
  useEffect(() => {
    loadPredictions();
  }, [loadPredictions]);

  if (items.length === 0) {
    return (
      <div className="rounded-lg border border-border p-8 text-center text-muted-foreground">
        No activity yet
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {items.map((item) => (
        <div key={item.type === 'comment' ? item.data.id : item.data.id}>
          {item.type === 'comment' ? (
            <CommentBlurt comment={item.data} article={item.article} />
          ) : (
            <PredictionBlurt prediction={item.data} />
          )}
        </div>
      ))}
    </div>
  );
}