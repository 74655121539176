import { create } from 'zustand';
import { initialBlogPosts } from '@/lib/data/blog-posts';

interface BlogPost {
  id: string;
  url: string;
  title: string;
  content: string;
  excerpt: string;
  author: string;
  date: string;
  tags: string[];
  image?: string;
}

interface BlogState {
  posts: BlogPost[];
  loadPosts: () => void;
  addPost: (post: Omit<BlogPost, 'id'>) => void;
  updatePost: (id: string, post: Partial<BlogPost>) => void;
  removePost: (id: string) => void;
}

export const useBlogStore = create<BlogState>((set) => ({
  posts: initialBlogPosts,
  loadPosts: () => set({ posts: initialBlogPosts }),
  addPost: (post) => set((state) => ({
    posts: [{
      ...post,
      id: Math.random().toString(36).slice(2),
    }, ...state.posts]
  })),
  updatePost: (id, updates) => set((state) => ({
    posts: state.posts.map(post =>
      post.id === id ? { ...post, ...updates } : post
    )
  })),
  removePost: (id) => set((state) => ({
    posts: state.posts.filter(post => post.id !== id)
  })),
}));