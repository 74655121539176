import { useEffect } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { EventCard } from './EventCard';
import { SectionHeader } from '../section/SectionHeader';
import { useEventsStore } from '@/lib/store/events-store';

export function RecentEventsSection() {
  const { events, loadEvents } = useEventsStore();

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  if (!events?.length) return null;

  // Sort events by start date
  const sortedEvents = [...events].sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  );

  return (
    <section className="space-y-4">
      <SectionHeader 
        title="Notable Events" 
        keywords={['Conferences', 'Meetups', 'Industry Events']}
      />
      
      <ScrollArea className="w-full pb-4">
        <div className="flex gap-6">
          {sortedEvents.map((event) => (
            <div 
              key={event.id}
              className="w-[360px] flex-none first:ml-0"
            >
              <EventCard event={event} />
            </div>
          ))}
        </div>
      </ScrollArea>
    </section>
  );
}