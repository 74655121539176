import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { FEED_PRESETS } from '@/lib/constants/feeds';

interface FeedSettings {
  enabledFeeds: string[];
  setEnabledFeeds: (feeds: string[]) => void;
}

export const useFeedSettings = create<FeedSettings>()(
  persist(
    (set) => ({
      enabledFeeds: Object.entries(FEED_PRESETS.default)
        .filter(([_, enabled]) => enabled)
        .map(([key]) => key),
      setEnabledFeeds: (feeds) => {
        // Always ensure bitcoin feed is enabled
        const updatedFeeds = Array.from(new Set(['bitcoin', ...feeds]));
        set({ enabledFeeds: updatedFeeds });
      },
    }),
    {
      name: 'feed-settings',
      version: 1,
    }
  )
);